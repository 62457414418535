import { decamelize } from 'humps';
import { serialize } from 'object-to-formdata';

import { SalesWebAppForm } from '@/types/sales-web-app-form';
import type { WebApp } from '@/types/web-app';
import type { WebAppCountryService } from '@/types/web-app-country-service';
import type { WebAppCountryServicesForm } from '@/types/web-app-country-services-form';
import { WebAppForm } from '@/types/web-app-form';
import type { WebAppOrderParam } from '@/types/web-app-order-param';

import api from './index';

const BASE_PATH = '/api/internal/web_apps';

type WebAppCountryServiceAttribute = {
  id?: number;
  countryServiceId: number;
  _destroy: number;
}

function buildWebAppCountryServicesAttributes(
  newCountryServicesIds: number[],
  deletedWebAppCountryServices: WebAppCountryService[],
):WebAppCountryServiceAttribute[] {
  const newWebAppCountryServiceAttributes = newCountryServicesIds.map((countryServiceId) => {
    const attribute: WebAppCountryServiceAttribute = { countryServiceId, _destroy: 0 };

    return attribute;
  });

  const deletedWebAppCountryServicesAttributes = deletedWebAppCountryServices.map((webAppCountryService) => {
    const id = webAppCountryService.id;
    const countryServiceId = webAppCountryService.countryService.id;
    const attribute: WebAppCountryServiceAttribute = { id, countryServiceId, _destroy: 1 };

    return attribute;
  });

  return newWebAppCountryServiceAttributes.concat(deletedWebAppCountryServicesAttributes);
}

export default {
  create(webApp: WebAppForm | SalesWebAppForm) {
    return api({
      method: 'post',
      url: BASE_PATH,
      data: serialize(
        { webApp },
      ),
    });
  },
  update(
    webAppId: number,
    webApp: Partial<WebApp> & { event?: string },
    reconnect: boolean,
  ) {
    const path = `${BASE_PATH}/${webAppId}`;
    if (reconnect) webApp.event = 'heroku_reconnect';

    return api({
      method: 'patch',
      url: path,
      data: serialize({ webApp }),
    });
  },
  connect(webAppId: number) {
    const path = `${BASE_PATH}/${webAppId}`;

    return api({
      method: 'patch',
      url: path,
      data: { webApp: { event: 'heroku_reconnect' } },
    });
  },
  index(order?: WebAppOrderParam) {
    return api({
      method: 'get',
      url: BASE_PATH,
      params: { q: { s: decamelize(order || '') } },
    }).then(response => response.data?.webApps);
  },
  show(webAppId: number) {
    const path = `${BASE_PATH}/${webAppId}`;

    return api({
      method: 'get',
      url: path,
    }).then(response => response.data?.webApp);
  },
  updateCountryServices(webAppCountryServicesForm: WebAppCountryServicesForm) {
    const {
      webApp,
      newCountryServicesIds,
      deletedWebAppCountryServices,
    } = webAppCountryServicesForm;
    const path = `${BASE_PATH}/${webApp.id}`;

    const webAppCountryServicesAttributes = buildWebAppCountryServicesAttributes(
      newCountryServicesIds, deletedWebAppCountryServices,
    );

    return api({
      method: 'patch',
      url: path,
      data: serialize(
        { webApp: { ...webApp, webAppCountryServicesAttributes } }),
    });
  },
  delete(webAppId: number) {
    const path = `${BASE_PATH}/${webAppId}`;

    return api({
      method: 'delete',
      url: path,
    });
  },
};
