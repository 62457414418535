<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import useSharedToggleDropdown from '@/composables/useSharedToggleDropdown';
import type { WebApp } from '@/types/web-app';

const { t } = useI18n();

interface Props {
  webApp: WebApp;
}

const props = defineProps<Props>();

const hasVariousOwners = computed(() => props.webApp.ownerNames.length > 1);
const hasOneOwner = computed(() => props.webApp.ownerNames.length === 1);

const ownerText = computed(() => {
  const { ownerNames } = props.webApp;

  if (hasVariousOwners.value) {
    return t('webApp.fields.variousOwners');
  } else if (hasOneOwner.value) {
    return ownerNames[0];
  }

  return t('webApp.missingFields.owner');
});

const { toggledDropdownId, toggleDropdown } = useSharedToggleDropdown();

const dropdownOpen = computed(() => toggledDropdownId.value === props.webApp.id);

</script>
<template>
  <div
    v-if="hasVariousOwners"
  >
    <button
      class="flex"
      data-testid="toggle-dropdown"
      @click="toggleDropdown(props.webApp.id)"
    >
      {{ ownerText }}
      <base-svg
        :name="dropdownOpen ? 'chevron-up' : 'chevron-down'"
        class="ml-1 h-4 w-4"
      />
    </button>
    <div
      v-if="dropdownOpen"
      class="absolute z-10 flex w-40 flex-col gap-5 rounded-lg border border-gray-100 bg-white px-4 py-2.5 shadow-sm"
    >
      <div
        v-for="name in props.webApp.ownerNames"
        :key="name"
      >
        <p>{{ name }}</p>
      </div>
    </div>
  </div>
  <p v-else>
    {{ ownerText }}
  </p>
</template>
