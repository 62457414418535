<script setup lang="ts">
import { computed, inject } from 'vue';

import type { Category } from '@/types/category';

interface Props {
  family: Category;
  categories: Category[];
  alreadyAdded: boolean;
}

const props = defineProps<Props>();

const selectedFamilyIds = inject('selectedFamilyIds', [] as number[]);

const categoriesString = computed(() => props.categories.map((category) => category.name).join(', '));
</script>

<template>
  <div
    class="w-full rounded border border-slate-200 bg-white px-4 py-3"
  >
    <div class="flex flex-col gap-2">
      <div class="flex flex-wrap items-center gap-2 text-sm">
        <base-checkbox-input
          v-if="alreadyAdded"
          name="alreadyAddedFamilies"
          :model-value="true"
          :value="true"
          disabled
        />
        <base-checkbox-input
          v-else
          v-model="selectedFamilyIds"
          name="families"
          :value="family.id"
        />
        <p> {{ family.name }}</p>
        <base-pill
          v-if="alreadyAdded"
          class="hidden text-xs md:block"
          theme="success"
          size="sm"
        >
          {{ $t('webAppCategories.addFamilies.added') }}
        </base-pill>
        <div
          v-if="alreadyAdded"
          class="w-full text-xs md:hidden"
        >
          <base-pill
            class="w-fit"
            theme="success"
            size="sm"
          >
            {{ $t('webAppCategories.addFamilies.familyAdded') }}
          </base-pill>
        </div>
      </div>
      <p class="text-xs">
        {{ categories.length > 0 ?
          $t('webAppCategories.addFamilies.categories', { categories: categoriesString }) :
          $t('webAppCategories.addFamilies.noCategories')
        }}
      </p>
    </div>
  </div>
</template>
