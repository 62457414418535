<script setup lang="ts">
import { ref, watch } from 'vue';

import type { WebAppOrderParam } from '@/types/web-app-order-param';

interface Props {
  headerI18nKey: string;
  headerField: string;
  selectedOrder?: WebAppOrderParam;
  sortable?: boolean;
}

const props = withDefaults(defineProps<Props>(),
  {
    selectedOrder: undefined,
    sortable: true,
  },
);

interface Emit {
  (event: 'order', order: WebAppOrderParam): void
}

const emit = defineEmits<Emit>();

const currentOrder = ref<'asc' | 'desc'>();

function handleOrderChange() {
  currentOrder.value = currentOrder.value === 'asc' ? 'desc' : 'asc';
  emit('order', `${props.headerField} ${currentOrder.value}` as WebAppOrderParam);
}

watch(() => props.selectedOrder, (newValue : string | undefined) => {
  if (newValue?.includes(props.headerField)) {
    currentOrder.value = newValue.includes('asc') ? 'asc' : 'desc';
  } else {
    currentOrder.value = undefined;
  }
});

</script>
<template>
  <th class="py-6">
    <div class="flex gap-1">
      {{ $t(`webApp.fields.${headerI18nKey}`) }}
      <button
        v-if="sortable"
        data-testid="sortable-button"
        class="flex gap-0"
        @click="handleOrderChange"
      >
        <span
          :class="selectedOrder === `${headerField} asc` && 'text-primary-800'"
        >
          ↓
        </span>
        <span
          :class="selectedOrder === `${headerField} desc` && 'text-primary-800'"
        >
          ↑
        </span>
      </button>
    </div>
  </th>
</template>
