<script setup lang="ts">
import { computed, useAttrs, ref, watch } from 'vue';

import useAttrsWithoutClass from '@/composables/useAttrsWithoutClass';

type Theme = 'primary' | 'secondary' | 'gray' |
'light-link' | 'primary-link' | 'blue-link' | 'success-link' | 'error-link';

type ThemeStyles = {
  [key in Theme]:string;
}

type IconPosition = 'start' | 'end';

interface Props {
  iconFileName?: string;
  theme?: Theme;
  /** The size of the icon */
  size?: 'xs' | 'sm' | 'md';
  loading?: boolean;
  iconPosition?: IconPosition;
  disabled?: boolean;
  /** The text to display in the button. It must be a key in the i18n file. */
  label?: string;
}

const props = withDefaults(
  defineProps<Props>(),
  {
    iconFileName: undefined,
    theme: 'light-link',
    size: 'md',
    loading: false,
    iconPosition: undefined,
    disabled: false,
    label: undefined,
  },
);

defineOptions({
  inheritAttrs: false,
});

const themeStyles: ThemeStyles = {
  'primary': 'text-white bg-primary-700 px-6 hover:bg-primary-600 disabled:text-white disabled:bg-gray-300',
  'secondary': `text-primary-700 border border-primary-700 px-6 hover:bg-primary-100
                disabled:text-gray-300 disabled:border-gray-300`,
  'gray': 'text-gray-600 border border-gray-300 focus:text-gray-700 disabled:text-gray-300  disabled:border-gray-200',
  'light-link': 'text-gray-600 disabled:text-gray-300',
  'primary-link': 'text-primary-700 disabled:text-gray-300',
  'blue-link': 'text-blue-500 disabled:text-gray-300',
  'success-link': 'text-success-700 disabled:text-gray-300',
  'error-link': 'text-error-700 disabled:text-gray-300',
};

const iconSizeStyle = {
  xs: 'h-2.5',
  sm: 'h-4',
  md: 'h-5 xl:h-6',
};

const attrs = useAttrs();
const attrsWithoutClass = useAttrsWithoutClass();
const themeStyle = computed(() => themeStyles[props.theme]);
const currentTag = computed(() => {
  const hasHrefAndIsNotDisabled = attrs.href && !props.disabled;

  return hasHrefAndIsNotDisabled ? 'a' : 'button';
});

const showIcon = computed(() => (!!props.iconFileName));

const iconAtEnd = computed(() => (props.iconPosition === 'end'));

const contentRef = ref<HTMLElement>();
const contentWidth = computed(() => contentRef.value?.clientWidth || 0);
const lastContentWidth = ref<number>(contentWidth.value);

watch(contentWidth, (newContentWidth) => {
  if (newContentWidth > 0) {
    lastContentWidth.value = newContentWidth;
  }
});
const loadingWidthClass = computed(() => (`min-w-[${lastContentWidth.value}px]`));

</script>
<template>
  <component
    :is="currentTag"
    class="inline-flex h-min justify-center rounded-md py-2"
    :class="[themeStyle, loadingWidthClass ]"
    :disabled="disabled"
    v-bind="attrsWithoutClass"
  >
    <div
      v-if="loading"
      class="flex items-center justify-center"
      :class="loadingWidthClass"
    >
      <base-svg
        name="loading"
        class="h-6 animate-spin stroke-current"
      />
    </div>
    <div
      v-else
      ref="contentRef"
      data-testid="base-button-content"
      class="inline-flex items-center justify-center text-center"
      :class="{'flex-row-reverse': iconAtEnd }"
    >
      <base-svg
        v-if="showIcon"
        data-testid="base-button-icon"
        :name="props.iconFileName"
        class="mx-1 stroke-current"
        :class="iconSizeStyle[size]"
      />
      <span v-if="label">
        {{ label }}
      </span>
    </div>
  </component>
</template>
