<script setup lang="ts">
import { PhInfo } from '@phosphor-icons/vue';
import isEqual from 'lodash/isEqual';
import { useForm } from 'vee-validate';
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMutation } from 'vue-query';
import { number, object, string } from 'yup';

import webAppApi from '@/api/webApp';
import { LegalDocument } from '@/types/legal-document';
import { WebApp } from '@/types/web-app';
import type { WebAppTabs } from '@/types/web-app-tabs';

import BaseMokButton from './base-mok-button.vue';
import BaseMokInput from './base-mok-input.vue';
import WebAppFunctionalitiesLayout from './web-app-functionalities-layout.vue';

export type Props = {
  webApp: WebApp;
  backPath: string;
  webAppTabs: WebAppTabs;
  legalDocuments: LegalDocument[];
};

const props = defineProps<Props>();

const isUpdateSuccess = ref(false);
const isUpdateError = ref(false);

const { t } = useI18n();

interface WebAppCopyright {
  text: string;
  year: string;
  copyright: string;
}

const currentYear = new Date().getFullYear();
const minYear = 2000;

const webAppSchema = object({
  text: string().notRequired(),
  year: number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .typeError(
      t(
        'functionalities.legalDocuments.copyright.inputs.year.validations.typeError',
      ),
    )
    .integer(
      t(
        'functionalities.legalDocuments.copyright.inputs.year.validations.integer',
      ),
    )
    .min(
      minYear,
      `${t('functionalities.legalDocuments.copyright.inputs.year.validations.min')} ${minYear}`,
    )
    .max(
      currentYear,
      `${t('functionalities.legalDocuments.copyright.inputs.year.validations.max')} ${currentYear}`,
    )
    .notRequired(),
  copyright: string(),
});
const initialWebAppCopyright = reactive<WebAppCopyright>({
  text: props.webApp?.copyrightText || '',
  year: (props.webApp?.copyrightYear || '').toString(),
  copyright: 'Copyright ©',
});

const {
  resetForm,
  values: webAppCopyright,
  setValues: setWebAppCopyright,
  validate,
  errors,
} = useForm<WebAppCopyright>({
  initialValues: { ...initialWebAppCopyright },
  validationSchema: webAppSchema,
  validateOnMount: true,
});
const disabledActions = computed(() =>
  isEqual(webAppCopyright, initialWebAppCopyright),
);
const webAppId = computed(() => props.webApp?.id || 0);

const { mutate: updateWebAppCopyrightMutation, isLoading: isUpdateLoading } =
  useMutation(
    () =>
      webAppApi.update(
        webAppId.value,
        {
          copyrightText: webAppCopyright.text,
          copyrightYear: Number(webAppCopyright.year),
        },
        false,
      ),
    {
      onSuccess: (response) => {
        isUpdateSuccess.value = true;
        const copyrightResponse = {
          text: response.data.webApp?.copyrightText || '',
          year: (response.data.webApp?.copyrightYear || '').toString(),
        };
        setWebAppCopyright(copyrightResponse);
        Object.assign(initialWebAppCopyright, copyrightResponse);
      },
      onError: () => {
        isUpdateError.value = true;
      },
    },
  );

async function updateCopyright() {
  await validate();
  if (Object.keys(errors.value).length !== 0) return;
  updateWebAppCopyrightMutation();
}
</script>

<template>
  <web-app-functionalities-layout
    :web-app="webApp"
    :back-path="backPath"
    :web-app-tabs="webAppTabs"
    selected-tab="legalDocuments"
    :is-update-success="isUpdateSuccess"
    :is-update-error="isUpdateError"
  >
    <form
      id="web-app-copyright-form"
    >
      <div class="mt-6 flex flex-col gap-4">
        <h2 class="text-xs font-semibold text-gray-900">
          Copyright
        </h2>
        <p class="text-xs font-normal text-gray-500">
          {{ $t("functionalities.legalDocuments.copyright.title") }}
        </p>
        <div class="flex flex-row justify-between gap-6">
          <base-mok-input
            v-model="webAppCopyright.text"
            name="text"
            :placeholder="
              $t('functionalities.legalDocuments.copyright.inputs.name.title')
            "
            class="w-1/3"
            data-testid="copyright-text-input"
          />
          <base-mok-input
            name="copyright"
            class="w-1/3"
            disabled
            :value="webAppCopyright.copyright"
            data-testid="disabled-input"
          />
          <base-mok-input
            v-model="webAppCopyright.year"
            name="year"
            :placeholder="
              $t('functionalities.legalDocuments.copyright.inputs.year.title')
            "
            class="w-1/3"
            data-testid="copyright-year-input"
          />
        </div>
        <hr class="border-gray-200">
      </div>
    </form>
    <div
      class="mt-6 flex flex-row items-center rounded-xl border border-primary-300 px-4 py-3 shadow-md"
    >
      <base-mok-icon
        :icon="PhInfo"
        size="md"
        shape="rounded"
        class="mb-0 mr-3"
      />
      <p class="text-xs text-gray-500">
        {{ $t("functionalities.legalDocuments.information.title") }}
      </p>
    </div>
    <div class="my-6 flex flex-col gap-6">
      <div
        v-for="(legalDocument, index) in legalDocuments"
        :key="legalDocument.name"
        class="flex flex-col gap-4"
      >
        <h2 class="text-xs font-semibold text-gray-900">
          {{ legalDocument.name }}
        </h2>
        <div class="flex flex-row justify-between">
          <p class="text-xs font-normal text-gray-500">
            {{ $t("functionalities.legalDocuments.explanation") }}
          </p>
          <div
            class="flex items-center rounded-2xl px-3 py-1.5"
            :class="
              legalDocument.requiredInSignup ? 'bg-success-100' : 'bg-error-100'
            "
          >
            <div
              class="mr-1 h-3 w-3 rounded-full"
              :class="
                legalDocument.requiredInSignup
                  ? 'bg-success-700'
                  : 'bg-error-700'
              "
            />
            <p
              class="text-xs font-medium"
              :class="
                legalDocument.requiredInSignup
                  ? 'text-success-700'
                  : 'text-error-700'
              "
            >
              {{
                legalDocument.requiredInSignup
                  ? $t("functionalities.legalDocuments.active")
                  : $t("functionalities.legalDocuments.notActive")
              }}
            </p>
          </div>
        </div>
        <hr
          v-if="index !== legalDocuments.length - 1"
          class="border-gray-200"
        >
      </div>
    </div>

    <div
      class="sticky bottom-0 right-0 flex justify-end gap-4 bg-white pb-4 text-sm md:text-base"
    >
      <base-mok-button
        variant="secondary"
        :disabled="disabledActions"
        :label="$t('actions.cancel')"
        data-testid="cancel-button"
        @click="resetForm"
      />
      <base-mok-button
        variant="primary"
        :loading="isUpdateLoading"
        :label="$t('actions.saveChanges')"
        :disabled="disabledActions"
        data-testid="save-button"
        @click="updateCopyright"
      />
    </div>
  </web-app-functionalities-layout>
</template>
