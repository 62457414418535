import { decamelizeKeys } from 'humps';

type QueryParamValue = string | number | boolean | number[] | undefined;
type QueryParams = { [key: string]: QueryParamValue };

function createQueryParams(params: QueryParams) {
  const decamelizedParams = Object.entries(decamelizeKeys(params));
  const activeParams = decamelizedParams.filter(([, value]) => value !== undefined);

  const arrayProcessedParams = activeParams.flatMap(([key, value]) => {
    if (Array.isArray(value)) {
      return value.map((val) => [`${key}[]`, val]);
    }

    return [[key, value]];
  });

  return new URLSearchParams(arrayProcessedParams as string[][]).toString();
}

export type { QueryParamValue, QueryParams };
export { createQueryParams };
