<script setup lang="ts">

interface Props {
  theme: 'success' | 'warning'| 'error';
}

defineProps<Props>();

const styles = {
  success: 'inline-block border border-success-600 bg-success-100 text-sm text-success-600 rounded-full py-2 px-3',
  warning: 'inline-block border border-warning-600 bg-warning-100 text-sm text-warning-600 rounded-full py-2 px-3',
  error: 'inline-block border border-error-600 bg-error-100 text-sm text-error-600 rounded-full py-2 px-3',
  edit: 'bg-warning-50 text-warning-800 text-xs rounded-full px-2 py-0.5',
  dropdown: 'bg-slate-50 text-xs rounded-full px-3 py-0.5',
};

</script>

<template>
  <div
    class="transition-opacity"
    :class="styles[theme]"
  >
    <slot />
  </div>
</template>
