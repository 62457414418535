import { computed, reactive } from 'vue';

import type { Section } from '@/types/section';

function buildInitialSectionsPositions(sections: Section[]) {
  const positions: Record<string, number> = {};
  sections.forEach((section: Section) => {
    positions[section.id.toString()] = section.position;
  });

  return positions;
}

export function useSortedSections(
  { sections, selectedSectionId }: {
    sections: Section[],
    selectedSectionId: number,
  },
) {
  const sectionsPositions = reactive(buildInitialSectionsPositions(sections));

  function getCurrentPosition(sectionId: number) {
    return sectionsPositions[sectionId.toString()];
  }

  const selectedSectionPosition = computed(() => getCurrentPosition(selectedSectionId));
  const canMoveUp = computed(() => (selectedSectionPosition.value > 0));
  const canMoveDown = computed(() => (selectedSectionPosition.value < sections.length - 1));

  function moveSectionUp() {
    if (!canMoveUp.value) return;

    const currentSelectedSectionPosition = sectionsPositions[selectedSectionId.toString()];
    Object.keys(sectionsPositions).forEach((sectionId: string) => {
      const sectionPosition = sectionsPositions[sectionId];
      if (sectionPosition === currentSelectedSectionPosition - 1) {
        sectionsPositions[sectionId] += 1;
      }
    });
    sectionsPositions[selectedSectionId] -= 1;
  }

  function moveSectionDown() {
    if (!canMoveDown.value) return;

    const currentSelectedSectionPosition = sectionsPositions[selectedSectionId.toString()];
    Object.keys(sectionsPositions).forEach((sectionId: string) => {
      const sectionPosition = sectionsPositions[sectionId];
      if (sectionPosition === currentSelectedSectionPosition + 1) {
        sectionsPositions[sectionId] -= 1;
      }
    });
    sectionsPositions[selectedSectionId] += 1;
  }

  function resetPositions() {
    Object.assign(sectionsPositions, buildInitialSectionsPositions(sections));
  }

  const sortedSections = computed(() => {
    const sectionsDuplicate = [...sections];

    return sectionsDuplicate.sort(
      (a, b) => sectionsPositions[a.id.toString()] - sectionsPositions[b.id.toString()],
    );
  });

  return {
    sortedSections,
    selectedSectionPosition,
    moveSectionUp,
    moveSectionDown,
    resetPositions,
    getCurrentPosition,
    canMoveUp,
    canMoveDown,
  };
}
