<script setup lang="ts">
import { useField } from 'vee-validate';
import { computed } from 'vue';

import useAttrsWithoutClass from '@/composables/useAttrsWithoutClass';
import useFieldError from '@/composables/useFieldError';

const labelSizeClass = {
  xs: `text-xs peer-placeholder-shown:text-sm peer-focus:text-xs -top-3
       peer-focus:-top-3 peer-placeholder-shown:top-1`,
  sm: `text-sm peer-placeholder-shown:text-base peer-focus:text-sm -top-3.5
      peer-focus:-top-3.5 peer-placeholder-shown:top-1`,
};

const labelWeightClass = {
  bold: 'font-bold',
  semibold: 'font-semibold',
  normal: 'font-normal',
};

const inputSizeClass = {
  xs: 'h-8 placeholder:text-xs text-xs',
  sm: 'h-10 placeholder:text-sm text-sm',
};

interface Props {
  modelValue?: string | number;
  label?: string;
  name: string;
  type?: string;
  is?: 'input' | 'textarea';
  size?: keyof typeof labelSizeClass;
  /** The font weight of the label. */
  weight?: keyof typeof labelWeightClass;
  description?: string;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  label: undefined,
  type: 'text',
  is: 'input',
  size: 'sm',
  weight: 'normal',
  description: undefined,
  disabled: false,
});

const attrsWithoutClass = useAttrsWithoutClass();
const emit = defineEmits<{(e: 'update:modelValue', value: string): void}>();

const {
  value: inputValue,
  errorMessage,
  meta,
  handleBlur,
  handleChange,
} = useField(props.name, undefined, {
  initialValue: props.modelValue,
});

const { showError, hideError } = useFieldError({ errorMessage, meta });

function handleInputChange(e: Event) {
  handleChange(e);
  const input = e.target as HTMLInputElement;
  emit('update:modelValue', input.value);
}

const isTextArea = computed(() => props.is === 'textarea');

</script>
<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<template>
  <div
    class="relative flex flex-col space-y-1"
    :class="[$attrs.class, { 'opacity-70': disabled }]"
  >
    <component
      :is="is"
      :id="name"
      :name="name"
      :value="inputValue"
      :type="type"
      class="peer w-full rounded border text-gray-700 focus:border-primary-700 focus:ring-primary-700"
      :class="[{
        'placeholder:text-transparent': label,
        'border-error-600 placeholder:text-error-300': showError,
        'border-gray-300': hideError,
        'h-20 pt-2': isTextArea,
      }, inputSizeClass[size]]"
      v-bind="attrsWithoutClass"
      :disabled="disabled"
      @input="handleInputChange"
      @blur="handleBlur"
    />
    <label
      v-if="label"
      :for="name"
      class="absolute left-3 bg-white font-bold text-gray-700 transition-all peer-placeholder-shown:font-normal peer-placeholder-shown:text-gray-700 peer-focus:font-bold peer-focus:text-primary-700 "
      :class="[labelSizeClass[size], labelWeightClass[weight]]"
    >
      {{ label }}
    </label>
    <p
      v-show="description"
      class="text-left text-xs text-gray-400"
    >
      {{ description }}
    </p>
    <p
      v-show="showError"
      class="text-left text-xs text-error-600"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
