<script setup lang="ts">
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { useForm } from 'vee-validate';
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMutation } from 'vue-query';
import { object, string, number } from 'yup';

import webAppApi from '@/api/webApp';
import BaseBadge from '@/components/base-badge.vue';
import useModal from '@/composables/useModal';
import useVisibilityTimeout from '@/composables/useVisibilityTimeout';
import type { DesignConfig } from '@/types/design-config';
import type { WebApp } from '@/types/web-app';
import type { WebAppForm } from '@/types/web-app-form';
import type { WebAppTabs } from '@/types/web-app-tabs';
import regexPatterns from '@/values/regex-patterns';

import WebAppConnectionModal from './web-app-connection-modal.vue';

export type Props = {
  webApp: WebApp;
  designConfig: DesignConfig;
  backPath: string;
  tabs: WebAppTabs;
}

const props = defineProps<Props>();

const { t } = useI18n({});
const isSalesApp = computed(() => (props.webApp.appType === 'sales'));

const webAppSchema = object({
  contractId: number().typeError(t('validates.number', { field: t('webApp.fields.contractId') }))
    .label(t('webApp.fields.contractId'))
    .transform(value => (isNaN(value) ? undefined : value))
    .nullable(),
  name: string().required().label(t('webApp.fields.name')),
  herokuAppName: string().matches(
    regexPatterns.kebabCaseWithNumbers,
    t('webApp.errors.herokuAppNameFormat'),
  ).required()
    .label(t('webApp.fields.herokuAppName')),
});

const initialWebAppAttributes = ref({
  contractId: props.webApp.contractId,
  sponsorId: props.webApp.sponsorId,
  name: props.webApp.name,
  herokuAppName: props.webApp.herokuAppName,
  herokuConnected: props.webApp.herokuConnected,
});

const initialDesignConfigAttributes = ref({
  primaryColor: props.designConfig.primaryColor,
  secondaryColor: props.designConfig.secondaryColor,
  logo: undefined,
  darkBgLogo: undefined,
  faviconLogo: undefined,
  mobileLogo: undefined,
  darkMobileLogo: undefined,
});

const initialValues = computed(() => ({
  ...initialWebAppAttributes.value,
  designConfigAttributes: {
    ...initialDesignConfigAttributes.value,
  },
}));

const { resetForm, values: webAppData, setValues: setWebAppData, errors } = useForm<Partial<WebAppForm>>(
  {
    initialValues: initialValues.value,
    validationSchema: webAppSchema,
    validateOnMount: true,
  },
);

const webAppAttributes = computed(() => (
  omit(webAppData, 'designConfigAttributes')
));

const sameDesignConfigAttributes = computed(() => (
  isEqual(webAppData.designConfigAttributes, initialDesignConfigAttributes.value)
));

const sameWebAppAttributes = computed(() => {
  type InitialWebAppAttributesKeys = keyof typeof initialWebAppAttributes.value;
  const initialKeys = Object.keys(initialWebAppAttributes.value) as InitialWebAppAttributesKeys[];
  for (const key of initialKeys) {
    if (initialWebAppAttributes.value[key] !== webAppAttributes.value[key]) {
      return false;
    }
  }

  return true;
});

const disabledActions = computed(() => (sameWebAppAttributes.value && sameDesignConfigAttributes.value));

const herokuConnectedChanged = computed(() => (webAppData.herokuConnected !== props.webApp.herokuConnected));
const herokuAppNameChanged = computed(() => (webAppData.herokuAppName !== props.webApp.herokuAppName));

const appTypeIcons = {
  sales: 'shopping-bag',
  productive: 'user-check',
};
const appTypeTitle = computed(() => (`webApp.fields.appTypes.${props.webApp.appType}`));
const appTypeDescription = computed(() => (`webApp.fields.appTypesDescriptions.${props.webApp.appType}`));

const { isModalOpen, openModal, closeModal } = useModal();
const needsConfirmation = computed(() => (herokuConnectedChanged.value || herokuAppNameChanged.value));
const needsReconnection = computed(() => !!(needsConfirmation.value && webAppData.herokuConnected));

const {
  mutate: updateWebAppMutation,
  isLoading: isUpdateLoading,
  isError: isUpdateError,
  isSuccess: isUpdateSuccess,
} = useMutation(
  () => webAppApi.update(props.webApp.id, webAppData, needsReconnection.value),
  { onSuccess: (response) => {
    setWebAppData(response.data.webApp);
  } },
);

const VISIBILITY_TIMEOUT = 3000;

const { isVisible: isSuccessMessageVisible } = useVisibilityTimeout(
  { condition: isUpdateSuccess, timeout: VISIBILITY_TIMEOUT, reload: true },
);
const { isVisible: isErrorMessageVisible } = useVisibilityTimeout(
  { condition: isUpdateError, timeout: VISIBILITY_TIMEOUT, reload: true },
);

function updateWebApp() {
  if (Object.keys(errors.value).length !== 0) return;
  if (needsConfirmation.value) openModal();
  else updateWebAppMutation();
}

function confirmEdition() {
  updateWebAppMutation();
  closeModal();
}

</script>

<template>
  <the-web-app-layout
    :web-app="webApp"
    selected-tab="webApp"
    :tabs="tabs"
    :back-path="backPath"
  >
    <div class="flex justify-between">
      <h1 class="text-base font-semibold text-gray-900">
        {{ $t('components.webAppShow.title') }}
      </h1>
      <base-badge
        v-if="isErrorMessageVisible"
        :label="$t('components.webAppShow.errorMessage')"
        color="error"
        theme="dark"
      />
      <base-badge
        v-else-if="isSuccessMessageVisible"
        :label="$t('common.savedSuccessfully')"
        color="success"
        theme="dark"
      />
    </div>
    <form id="web-app-edit-form">
      <div class="flex flex-col md:grid md:grid-cols-2 md:gap-4 lg:grid-cols-3">
        <div class="col-span-1">
          <div class="my-6 flex flex-row space-x-2 rounded-lg border border-gray-300 bg-white p-5 text-xs">
            <div class="flex aspect-square h-10 items-center justify-center rounded-full bg-primary-100">
              <base-svg
                :name="appTypeIcons[props.webApp.appType]"
                class="stroke-primary-600"
              />
            </div>
            <div class="flex flex-col justify-between space-y-1 md:items-start">
              <label class="font-semibold text-gray-700">
                {{ $t(appTypeTitle) }}
              </label>
              <p class="text-xs text-gray-500">
                {{ $t(appTypeDescription) }}
              </p>
            </div>
          </div>
          <div class="my-6 rounded-lg border border-gray-300 bg-white p-5 text-xs">
            <div class="flex flex-col items-start justify-between space-y-2">
              <label class="font-semibold text-gray-700">
                {{ $t('webApp.fields.status') }}
              </label>
              <p class="text-xs text-gray-500">
                {{ $t('components.webAppShow.statusDescription') }}
              </p>
              <base-text-switch
                v-model="webAppData.herokuConnected"
                name="herokuConnected"
                :disabled="webApp.herokuConnected || !webApp.herokuAppName"
                :unchecked-label="$t('webApp.fields.statuses.disconnected')"
                :checked-label="$t('webApp.fields.statuses.connected')"
              />
            </div>
          </div>
          <div class="my-6 flex flex-col gap-y-6 rounded-lg border border-gray-300 bg-white p-5 text-xs">
            <base-logo-input
              name="designConfigAttributes.logo"
              :label="$t('designConfig.fields.logo')"
              :description="$t('designConfig.fields.logoDescription')"
              :preview-url="webApp.logoUrl"
            />
            <base-logo-input
              name="designConfigAttributes.darkBgLogo"
              :label="$t('designConfig.fields.darkBgLogo')"
              :description="$t('designConfig.fields.darkBgLogoDescription')"
              :preview-url="webApp.darkBgLogoUrl"
              dark-mode
            />
            <base-logo-input
              name="designConfigAttributes.faviconLogo"
              :label="$t('designConfig.fields.faviconLogo')"
              :description="$t('designConfig.fields.faviconLogoDescription')"
              :preview-url="webApp.faviconLogoUrl"
            />
            <base-logo-input
              name="designConfigAttributes.mobileLogo"
              :label="$t('designConfig.fields.mobileLogo')"
              :description="$t('designConfig.fields.mobileLogoDescription')"
              :preview-url="webApp.mobileLogoUrl"
            />
            <base-logo-input
              name="designConfigAttributes.darkMobileLogo"
              :label="$t('designConfig.fields.darkMobileLogo')"
              :description="$t('designConfig.fields.darkMobileLogoDescription')"
              :preview-url="webApp.darkMobileLogoUrl"
              dark-mode
            />
          </div>
        </div>
        <div class="col-span-1 text-xs lg:col-span-2">
          <div class="mb-8 mt-6 rounded-lg border border-gray-300 bg-white p-5">
            <h3 class="pb-3 text-xs font-semibold text-gray-900">
              {{ $t('components.webAppShow.webAppInfoTitle') }}
            </h3>
            <div class="grid grid-cols-1 gap-4 text-xs lg:grid-cols-2">
              <base-input
                :value="webApp.sponsorName"
                :label="$t('webApp.fields.sponsorName')"
                name="sponsorName"
                size="xs"
                weight="semibold"
                disabled
              />
              <base-input
                :value="webApp.countryName"
                :label="$t('webApp.fields.countryName')"
                name="countryName"
                size="xs"
                weight="semibold"
                disabled
              />
              <base-input
                :placeholder="$t('webApp.fields.name')"
                :label="$t('webApp.fields.name')"
                name="name"
                size="xs"
                weight="semibold"
              />
              <base-input
                :disabled="isSalesApp"
                :class="[{'opacity-50': isSalesApp}]"
                :placeholder="$t('webApp.fields.herokuAppName')"
                :label="$t('webApp.fields.herokuAppName')"
                name="herokuAppName"
                size="xs"
                weight="semibold"
              />
              <base-input
                :placeholder="$t('webApp.fields.contractId')"
                :label="$t('webApp.fields.contractId')"
                name="contractId"
                size="xs"
                weight="semibold"
                type="number"
              />
            </div>
          </div>
          <h2 class="pb-3 text-sm font-semibold text-gray-900">
            {{ $t('components.webAppShow.designConfigTitle') }}
          </h2>
          <div class="mb-6 mt-5 space-y-3 rounded-lg border border-gray-300 bg-white p-5">
            <div class="font-semibold text-gray-900">
              {{ $t('designConfig.fields.colors') }}
            </div>
            <div class="flex flex-col items-center gap-4 md:grid md:grid-cols-2">
              <div class="col-span-1 space-y-1">
                <p class="text-gray-900">
                  {{ $t('designConfig.fields.primaryColor') }}
                </p>
                <p class="text-xs text-gray-500">
                  {{ $t('designConfig.fields.primaryColorDescription') }}
                </p>
              </div>
              <base-color-input
                name="designConfigAttributes.primaryColor"
                placeholder="#FFFFFF"
                size="xs"
              />
            </div>
            <div class="flex flex-col items-center gap-4 md:grid md:grid-cols-2">
              <div class="col-span-1 space-y-1">
                <p class="text-gray-900">
                  {{ $t('designConfig.fields.secondaryColor') }}
                </p>
                <p class="text-xs text-gray-500">
                  {{ $t('designConfig.fields.secondaryColorDescription') }}
                </p>
              </div>
              <base-color-input
                name="designConfigAttributes.secondaryColor"
                placeholder="#FFFFFF"
                size="xs"
              />
            </div>
          </div>
          <div class="col-span-3 flex justify-end gap-4 text-sm md:text-base">
            <base-button
              theme="secondary"
              :label="$t('actions.cancel')"
              :disabled="disabledActions"
              @click="resetForm"
            />
            <base-button
              type="button"
              theme="primary"
              :loading="isUpdateLoading"
              :label="$t('actions.saveChanges')"
              :disabled="disabledActions"
              @click="updateWebApp"
            />
          </div>
        </div>
      </div>
    </form>
  </the-web-app-layout>
  <web-app-connection-modal
    :web-app-name="webApp.name"
    :heroku-app-name="webAppData.herokuAppName"
    :is-open="isModalOpen"
    @cancel="closeModal"
    @confirm="confirmEdition"
  />
</template>
