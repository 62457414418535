<script setup lang="ts">
import { computed, inject, reactive, ref, watch } from 'vue';

import type { Category } from '@/types/category';
import type { CountryService } from '@/types/country-service';

interface Props {
  family: Category;
  categories: Category[];
}

const props = defineProps<Props>();

const countryServices = inject<CountryService[]>('countryServices');
const selectedCountryServiceIds = inject('selectedCountryServiceIds', ref<number[]>([]));

const countryServicesByCategory = computed(() => [props.family].concat(props.categories).reduce((acc, category) => {
  acc[category.id] = countryServices?.filter((countryService) => (countryService.categoryId === category.id));

  return acc;
}, {} as Record<number, CountryService[] | undefined>));

function everyCountryServiceSelected(categoryId: number) {
  const countryServicesForCategoryIds = countryServicesByCategory.value[categoryId]?.map(
    (countryService) => (countryService.id),
  );

  return countryServicesForCategoryIds?.every((countryServiceId) =>
    (selectedCountryServiceIds.value.includes(countryServiceId))) || false;
}

function toggleCategory(categoryId: number, checked: boolean) {
  const countryServicesForCategoryIds = countryServicesByCategory.value[categoryId]?.map(
    (countryService) => (countryService.id),
  );

  countryServicesForCategoryIds?.forEach((countryServiceId) => {
    const index = selectedCountryServiceIds.value.indexOf(countryServiceId);
    if (checked) {
      if (index !== -1) return;
      selectedCountryServiceIds.value.push(countryServiceId);
    } else {
      if (index === -1) return;
      selectedCountryServiceIds.value.splice(index, 1);
    }
  });
}

const familyCountryServices = computed(() => (
  countryServicesByCategory.value[props.family.id] || []
));

function generateShownCategories() {
  const shownCategories: Category[] = props.categories;

  if (familyCountryServices.value.length > 0) {
    shownCategories.unshift(props.family);
  }

  return shownCategories;
}

const shownCategories = ref(generateShownCategories());

const categoriesToggled = reactive(
  shownCategories.value.reduce((acc, category) => {
    acc[category.id] = false;

    return acc;
  }, {} as Record<number, boolean>),
);

watch(selectedCountryServiceIds, () => {
  shownCategories.value.forEach((category) => {
    categoriesToggled[category.id] = everyCountryServiceSelected(category.id);
  });
});
</script>
<template>
  <div
    class="flex w-full flex-col rounded border border-slate-200 bg-white p-4"
  >
    <div class="flex gap-2">
      <h2>
        {{ family.name }}
      </h2>
      <base-pill
        theme="edit"
      >
        {{ $t('webAppCategories.addServices.family') }}
      </base-pill>
    </div>
    <div
      class="mt-6 flex flex-col gap-6"
    >
      <div
        v-for="(category, index) in shownCategories"
        :key="category.id"
      >
        <div class="flex w-full justify-between">
          <p class="text-sm">
            <span class="text-primary-700">•</span>
            {{ category.name === family.name ? $t('webAppCategories.addServices.noCategory') : category.name }}
          </p>
          <div class="flex items-center gap-2 text-xs">
            <base-switch
              v-model="categoriesToggled[category.id]"
              :name="category.name"
              @update:model-value="(value : boolean) => toggleCategory(category.id, value)"
            />
            <p>
              {{ $t('actions.addAll') }}
            </p>
          </div>
        </div>
        <div class="mt-5 flex flex-col flex-wrap gap-4 md:flex-row">
          <base-checkbox-input
            v-for="countryService in countryServicesByCategory[category.id]"
            :key="countryService.id"
            v-model="selectedCountryServiceIds"
            name="selectedCountryServices"
            :value="countryService.id"
            :label="countryService.name"
            class="text-xs"
          />
        </div>
        <div
          class="mt-6 h-0 w-full border-b border-slate-100"
          :class="index === shownCategories.length - 1 && 'hidden'"
        />
      </div>
    </div>
  </div>
</template>
