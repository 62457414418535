<script setup lang="ts">
import { useField } from 'vee-validate';
import { toRef, computed } from 'vue';

type Props = {
  modelValue?: unknown;
  value: unknown;
  name: string;
  checked?: boolean;
  label?: string;
  disabled?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  label: undefined,
  disabled: false,
  checked: false,
});
const id = computed(() => `radio-${props.name}-${props.value}`);

const emit = defineEmits<{(e: 'update:modelValue', value: unknown): void}>();

const name = toRef(props, 'name');

const { handleChange, checked, setTouched } = useField(props.name, undefined, {
  type: 'radio',
  checkedValue: props.value,
  initialValue: props.modelValue,
});

function onChange(e: Event) {
  const input = e.target as HTMLInputElement;
  setTouched(true);

  if (Array.isArray(props.modelValue)) {
    const newValue = [...props.modelValue];
    if (input.checked) {
      newValue.push(props.value);
    } else {
      newValue.splice(newValue.indexOf(props.value), 1);
    }
    emit('update:modelValue', newValue);
  } else {
    emit('update:modelValue', input.checked ? props.value : undefined);
  }
  handleChange(e);
}
</script>
<template>
  <div class="flex items-center gap-2">
    <input
      :id="id"
      :value="value"
      :name="name"
      :checked="checked"
      type="radio"
      :disabled="disabled"
      class="h-4 w-4 border-gray-300 bg-gray-100 text-primary-600"
      @change="onChange"
    >
    <label
      v-if="label"
      :for="id"
      class="mr-4 flex items-center border-gray-500 text-gray-800"
    >
      {{ label }}
    </label>
  </div>
</template>
