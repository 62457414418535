<script setup lang="ts">
import { useField } from 'vee-validate';
import { computed, useAttrs, ref, watch } from 'vue';

import useAttrsWithoutClass from '@/composables/useAttrsWithoutClass';
import useFieldError from '@/composables/useFieldError';

const labelSizeClass = {
  xs: 'text-xs',
  sm: 'text-sm',
};

const labelWeightClass = {
  bold: 'font-bold',
  normal: 'font-normal',
};

interface Props {
  modelValue?: File;
  label?: string;
  name: string;
  required?: boolean;
  /** The font size of the label. */
  size?: keyof typeof labelSizeClass;
  /** The font weight of the label. */
  weight?: keyof typeof labelWeightClass;
  previewUrl?: string;
  previewName?: string;
  alignImage?: 'start' | 'center';
  accept?: string;
  imageFileTypeLabel?: string;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  label: undefined,
  size: 'sm',
  weight: 'normal',
  previewUrl: undefined,
  previewName: undefined,
  alignImage: 'start',
  accept: 'image/png, image/jpeg, image/jpg',
  imageFileTypeLabel: undefined,
});

const {
  value: inputValue,
  errorMessage,
  meta,
  handleBlur,
  handleChange,
  resetField,
} = useField(props.name, undefined, {
  initialValue: props.modelValue,
});

const { showError, hideError } = useFieldError({ errorMessage, meta });

const fileInputRef = ref();
function previewImageFromFile(file: File) {
  return {
    url: URL.createObjectURL(file),
    name: file.name,
  };
}

const initialPreviewImage = computed(() => {
  if (props.previewUrl && props.previewName) {
    return { url: props.previewUrl, name: props.previewName };
  } else if (props.modelValue) {
    return previewImageFromFile(props.modelValue);
  }

  return undefined;
});

const previewImage = computed(() => {
  if (!inputValue.value) return initialPreviewImage.value;

  return previewImageFromFile(inputValue.value);
});

const attrs = useAttrs();
const attrsWithoutClass = useAttrsWithoutClass();

const emit = defineEmits<{(e: 'update:modelValue', value: File | undefined): void }>();

function setFile(e: Event) {
  handleChange(e);
  const fileInput = e.target as HTMLInputElement;
  if (fileInput.files && fileInput.files[0]) {
    const file = fileInput.files[0];
    emit('update:modelValue', file);
  } else {
    emit('update:modelValue', undefined);
  }
}

function changeImage() {
  fileInputRef.value.click();
}

const isDisabled = computed(() => attrs.disabled === '' || attrs.disabled);
const isUndoable = computed(() => (
  initialPreviewImage.value?.name !== previewImage.value?.name
));

watch(
  () => props.modelValue,
  (newModel) => {
    if (newModel === inputValue.value) {
      return;
    }
    inputValue.value = newModel;
  },
);

const imageAlignStyles = {
  start: 'justify-start',
  center: 'justify-center',
};

const imageAlignClass = computed(() => imageAlignStyles[props.alignImage]);
</script>
<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<template>
  <div>
    <div v-if="previewImage">
      <label
        v-if="label"
        :for="name"
        class="m-1 text-gray-700 "
        :class="[labelSizeClass[size], labelWeightClass[weight]]"
      >
        {{ label }}
      </label>
      <div
        class="m-1 flex rounded border p-2 outline-gray-700"
        :class="imageAlignClass"
      >
        <img
          :src="previewImage.url"
          class="max-h-40 rounded object-center opacity-70"
        >
      </div>
      <div
        v-if="!isDisabled"
        class="mt-1 flex justify-between gap-x-4 text-xs"
        data-testid="base-image-input-actions"
      >
        <p
          v-if="previewImage.name"
          class="m-1 text-ellipsis text-gray-500"
        >
          {{ previewImage.name }}
        </p>
        <div>
          <button
            v-if="isUndoable"
            :id="name"
            type="button"
            class="m-1 text-gray-500"
            @click="() => resetField()"
          >
            {{ $t('actions.undoChanges') }}
          </button>
          <button
            :id="name"
            type="button"
            class="m-1 text-blue-400"
            @click="changeImage"
          >
            {{ $t('common.changeFile') }}
          </button>
        </div>
      </div>
    </div>
    <label
      v-else
      :for="name"
      tabindex="0"
      class="cursor-pointer rounded outline-gray-700"
      :class="[$attrs.class, {'bg-gray-50': isDisabled }]"
    >
      <span
        v-if="label"
        class="mb-1 text-gray-700"
        :class="[labelSizeClass[size], labelWeightClass[weight]]"
      >
        {{ label }}
      </span>
      <div
        class="m-1 flex flex-col rounded border border-dashed px-1"
        :class="{'bg-gray-50': isDisabled, 'border border-error-600': showError, 'border-gray-300': hideError }"
      >
        <div
          class="mx-auto py-3 text-center text-xs"
        >
          <base-svg
            name="image-upload"
            class="mx-auto h-6 w-6 stroke-gray-400"
          />
          <div
            class="flex flex-col"
          >
            <p class="text-blue-500">
              {{ $t('common.selectFile') }}
            </p>
            <p
              :class="'text-gray-400'"
            >
              {{ imageFileTypeLabel || $t('common.imageFileType') }}
            </p>
          </div>
        </div>
      </div>
    </label>
    <input
      :id="name"
      ref="fileInputRef"
      type="file"
      :accept="accept"
      :name="name"
      v-bind="attrsWithoutClass"
      class="hidden"
      @change="setFile"
      @blur="handleBlur"
    >
    <p
      v-show="showError"
      class="text-left text-xs text-error-600"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
