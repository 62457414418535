<script setup lang="ts">
import { Combobox, ComboboxInput, ComboboxOptions, ComboboxOption } from '@headlessui/vue';
import { ref, computed, defineProps, defineEmits } from 'vue';

import type { SelectOption } from '@/types/select-option';

export interface Props {
  options: SelectOption[];
  modelValue?: SelectOption;
}

interface Emits {
  (e: 'update:modelValue', value: SelectOption): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const searchQuery = ref<string>('');
const selectedOption = ref<SelectOption | undefined>(props.modelValue);

const MIN_CHARS_FOR_SEARCH = 3;
const filteredOptions = computed<SelectOption[]>(() => {
  if (searchQuery.value.length < MIN_CHARS_FOR_SEARCH) return [];

  return props.options.filter(option => option.name.toLowerCase().includes(searchQuery.value.toLowerCase()));
});
const showOptions = computed(() => filteredOptions.value.length && searchQuery.value.length >= MIN_CHARS_FOR_SEARCH);

function onSelectOption(option: SelectOption) {
  emit('update:modelValue', option);
}
function updateSearchQuery(value: string) {
  searchQuery.value = value;
}

function clear() {
  searchQuery.value = '';
}
</script>

<template>
  <div class="relative">
    <Combobox
      v-slot="{ activeIndex }"
      :model-value="selectedOption"
      @update:model-value="onSelectOption"
    >
      <div class="relative">
        <ComboboxInput
          class="w-full rounded-md border border-gray-300 px-14 py-2 text-base text-gray-900 caret-primary-600 placeholder:text-gray-500 focus:border-primary-600 focus:ring-0 sm:text-sm"
          :placeholder="$t('webAppServices.form.searchPlaceholder')"
          autocomplete="off"
          :display-value="(option) => (option as SelectOption)?.name"
          @change="updateSearchQuery($event.target.value)"
          @blur="clear"
          @keyup.esc="clear"
        />
        <div class="absolute inset-y-0 left-0 flex cursor-pointer items-center pl-6">
          <base-svg
            name="search"
            class="h-4 w-4 fill-current text-gray-500"
          />
        </div>
        <button
          v-if="searchQuery"
          class="absolute inset-y-0 right-0 flex items-center pr-2"
          data-testid="clear-search"
          @click="clear"
        >
          <base-svg
            name="x-circle"
            class="h-4 w-4 fill-current text-primary-600"
          />
        </button>
      </div>
      <ComboboxOptions
        v-if="showOptions"
        class="mt-1 max-h-96 w-full overflow-auto rounded-md text-sm shadow-lg focus:outline-none"
      >
        <ComboboxOption
          v-for="(option, index) in filteredOptions"
          :key="`${option.id}-${option.name}`"
          as="template"
          :value="option"
        >
          <slot
            name="option"
            :option="option"
            :index="index"
            :active-index="activeIndex"
          >
            <div
              class="truncate p-3"
              :class="{
                'border-primary-200 bg-primary-25 text-primary-700': activeIndex === index,
                'border-gray-200 bg-white text-gray-900 ': activeIndex !== index,
                'border-t-primary-200': activeIndex === index - 1,
              }"
            >
              {{ option.name }}
            </div>
          </slot>
        </ComboboxOption>
      </ComboboxOptions>
    </Combobox>
  </div>
</template>
