<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import BaseRadioInputGroup from './base-radio-input-group.vue';

export interface Props {
  hasExtraInformationText: boolean;
  extraInformationText?: string;
}
interface Emits {
  (event: 'update:has-extra-information-text', value: boolean): void;
  (event: 'update:extra-information-text', value: string): void;
}

const { hasExtraInformationText, extraInformationText } = defineProps<Props>();

const emit = defineEmits<Emits>();

const { t } = useI18n({});
const hasExtraInfoOptions = computed(() => ([
  { value: true, label: t('webAppServices.form.fields.extraInformationText.options.true') },
  { value: false, label: t('webAppServices.form.fields.extraInformationText.options.false') },
]));
</script>

<template>
  <base-radio-input-group
    :model-value="hasExtraInformationText"
    data-testid="has-extra-information-text-radio-input"
    :options="hasExtraInfoOptions"
    name="hasExtraInformationText"
    :label="$t('webAppServices.form.fields.extraInformationText.label')"
    @update:model-value="emit('update:has-extra-information-text', $event as boolean)"
  />
  <base-rich-input
    v-if="hasExtraInformationText"
    :model-value="extraInformationText"
    data-testid="extra-information-text-rich-input"
    name="extraInformationText"
    weight="bold"
    :options="['bold', 'italic', 'bulletList', 'undo', 'redo']"
    @update:model-value="emit('update:extra-information-text', $event)"
  />
  <div
    v-if="hasExtraInformationText"
    class="rounded bg-gray-50 px-4 py-2 text-xs"
    data-testid="extra-information-text-explanation"
  >
    {{ $t(`webAppServices.form.fields.extraInformationText.explanation`) }}
  </div>
</template>
