<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMutation } from 'vue-query';

import webAppApi from '@/api/webApp';
import useWebAppCountryServicesQuery from '@/composables/useWebAppCountryServicesQuery';
import type { Category } from '@/types/category';
import type { CountryService } from '@/types/country-service';
import type { Service } from '@/types/service';
import type { WebAppCategory } from '@/types/web-app-category';
import type { WebAppCountryService } from '@/types/web-app-country-service';
import type { WebAppCountryServicesForm } from '@/types/web-app-country-services-form';

import CountryServicesSectionItem from './country-services-section-item.vue';
import SalesServicesSectionItem from './sales-services-section-item.vue';

const { t } = useI18n();

interface Props {
  category?: Category;
  family: Category;
  webAppCategory?: WebAppCategory;
  webAppCountryServices: WebAppCountryService[];
  countryServices: CountryService[];
  salesServices?: Service[];
  webAppId: number;
  isSalesApp?: boolean;
  hasLoyalty: boolean;
  mode?: 'new' | 'show'
}

interface Emits {
  (event: 'show', webAppCountryService: WebAppCountryService | Service): void,
  (event: 'edit', webAppCountryService: WebAppCountryService): void,
  (event: 'editCategory'): void,
  (event: 'createSalesService', category: Category): void,
  (event: 'editSalesService', salesService: Service): void,
}

const props = withDefaults(defineProps<Props>(),
  {
    webAppCategory: undefined,
    mode: 'show',
    category: undefined,
    isSalesApp: false,
    salesServices: undefined,
  },
);
const emit = defineEmits<Emits>();

const {
  refetch: refetchWebAppCountryServices,
} = useWebAppCountryServicesQuery(props.webAppId);

const {
  mutate,
} = useMutation(
  (values: WebAppCountryServicesForm) => webAppApi.updateCountryServices(values),
  { onSuccess: () => { refetchWebAppCountryServices.value(); } },
);

const newCountryServicesIds = ref<number[]>([]);

function updateWebAppCountryServices() {
  const webAppCountryServicesForm: WebAppCountryServicesForm = {
    webApp: { id: props.webAppId },
    newCountryServicesIds: newCountryServicesIds.value,
    deletedWebAppCountryServices: [],
  };
  mutate(webAppCountryServicesForm);
}

const availableCountryServices = computed(() => (
  props.countryServices.filter((countryService) => (
    !props.webAppCountryServices.find((webAppCountryService) =>
      (webAppCountryService.countryServiceId === countryService.id),
    )
  ))
));

const categoryName = computed(() => (
  props.webAppCategory?.customName ||
  props.category?.name ||
  t('countryServices.info.noCategory')
));

const showAvailableServices = ref(false);

const showAddServicesButton = computed(() => (
  !showAvailableServices.value &&
  (props.webAppCountryServices.length < props.countryServices.length)
));

const numberOfRedemptionServices = computed(() => (
  props.webAppCountryServices.filter((service) => service.pointCost > 0).length
));

function saveSelectedServices() {
  updateWebAppCountryServices();
  showAvailableServices.value = false;
}

const isCreateSalesServicesAllowed = computed(() => (
  props.isSalesApp && props.mode === 'show'
));

const isAddProductiveServicesAllowed = computed(() => (
  showAddServicesButton.value && props.mode === 'show'
));

const isSaveProductiveServiceAllowed = computed(() => (
  showAvailableServices.value || props.mode === 'new'
));

</script>
<template>
  <div class="grid grid-cols-2 items-center border border-x-0 border-gray-200 bg-white p-4 md:border-x md:px-8 md:py-2">
    <div class="flex gap-1 align-middle">
      <p>
        {{ categoryName }}
      </p>
      <p class="my-auto ml-2 text-xs text-gray-500">
        {{ $t("countryServices.info.numberOfServices",
              {added: webAppCountryServices.length, total: countryServices.length })
        }}
      </p>
    </div>
    <div
      class="flex flex-row items-center"
      :class="(hasLoyalty) ? 'justify-between' : 'justify-end'"
    >
      <p
        v-if="hasLoyalty"
        class="my-auto ml-2 text-xs text-gray-500"
      >
        {{ $t("countryServices.info.numberOfRedemptionServices",
              {total: numberOfRedemptionServices })
        }}
      </p>
      <base-button
        icon-file-name="pencil"
        size="md"
        :disabled="!category"
        :label="$t('actions.rename')"
        @click="emit('editCategory')"
      />
    </div>
  </div>
  <div class="col-span-2">
    <div class="flex flex-col">
      <div
        v-for="webAppCountryService in webAppCountryServices"
        :key="webAppCountryService.id"
        class="ml-3 flex h-14 items-center md:ml-8"
      >
        <base-svg
          name="subdirectory-arrow"
          class="mr-2 h-4 w-4 fill-current md:mr-2.5"
        />
        <country-services-section-item
          class="w-full"
          :item="webAppCountryService"
          :has-loyalty="hasLoyalty"
          @show="() => emit('show', webAppCountryService)"
          @edit="() => emit('edit', webAppCountryService)"
        />
      </div>
      <div
        v-for="salesService in salesServices"
        :key="salesService.id"
        class="ml-3 flex h-14 items-center md:ml-8"
      >
        <base-svg
          name="subdirectory-arrow"
          class="mr-2 h-4 w-4 fill-current md:mr-2.5"
        />
        <sales-services-section-item
          class="w-full"
          :item="salesService"
          :has-loyalty="hasLoyalty"
          @show="() => emit('show', salesService)"
          @edit="() => emit('editSalesService', salesService)"
        />
      </div>
      <div

        v-if="showAvailableServices || mode === 'new'"
        class="flex flex-col"
      >
        <div
          v-for="webAppCountryService in availableCountryServices"
          :key="webAppCountryService.id"
          class="ml-3 flex h-14 items-center md:ml-8"
        >
          <base-svg
            name="subdirectory-arrow"
            class="mr-2 h-4 w-4 fill-current md:mr-2.5"
          />
          <country-services-section-item
            class="w-full"
            :item="webAppCountryService"
            :has-loyalty="hasLoyalty"
            mode="available"
            @add="() => newCountryServicesIds.push(webAppCountryService.id)"
            @undo-add="() => newCountryServicesIds.splice(newCountryServicesIds.indexOf(webAppCountryService.id), 1)"
          />
        </div>
      </div>
      <div
        class="mt-4 flex"
        :class="isSalesApp? 'justify-between': 'justify-end'"
      >
        <div
          v-if="isCreateSalesServicesAllowed"
          class="ml-8"
        >
          <base-button
            theme="primary-link"
            :label="$t('services.actions.addSalesServices')"
            @click="$emit('createSalesService', category || family)"
          />
        </div>

        <div>
          <base-button
            v-if="isAddProductiveServicesAllowed"
            theme="secondary"
            :label="$t('countryServices.actions.addServices')"
            @click="showAvailableServices = true"
          />
          <base-button
            v-if="isSaveProductiveServiceAllowed"
            theme="primary"
            :label="$t('actions.save')"
            @click="saveSelectedServices"
          />
        </div>
      </div>
    </div>
  </div>
</template>
