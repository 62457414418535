import type { Coupon } from '@/types/coupon';

import api from './index';

const BASE_WEB_APP_PATH = '/api/internal/web_apps';

export default {
  async index(webAppId: number, queryParams?: string): Promise<Coupon[]> {
    let path = `${BASE_WEB_APP_PATH}/${webAppId}/coupons`;
    path += queryParams ? `?${queryParams}` : '';

    const response = await api({
      method: 'get',
      url: path,
    });

    return response.data.coupons as Coupon[];
  },
};
