<script setup lang="ts">
import {
  getSectionFieldLabel,
  getSectionFieldDescription,
} from '@/utils/get-section-field-translations';

export type Props = {
  name: string;
  baseType: string;
  modelValue?: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits<{(e: 'update:modelValue', value: boolean | undefined): void}>();

function handleChange(value: boolean | undefined) {
  emit('update:modelValue', value);
}

const label = getSectionFieldLabel(props.baseType, props.name);
const description = getSectionFieldDescription(props.baseType, props.name);

</script>

<template>
  <div class="space-y-3 rounded-lg border border-gray-300 p-5">
    <div class="flex w-full justify-between">
      <p class="text-base font-semibold text-gray-900">
        {{ label }}
      </p>
      <base-switch
        :model-value="modelValue"
        :name="name"
        @update:model-value="handleChange"
      />
    </div>
    <p class="text-xs font-normal text-gray-500">
      {{ description }}
    </p>
  </div>
</template>
