<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue';

import useWebAppCouponsQuery from '@/composables/useWebAppCouponsQuery';
import type { CuponstarCategory } from '@/types/cuponstar-category';
import type { WebApp } from '@/types/web-app';

const DEFAULT_MIN_DISCOUNT = 0;
const DEFAULT_MAX_DISCOUNT = 100;
const DEFAULT_MIN_POINT_COST = 0;
const DEFAULT_MAX_POINT_COST = 10_000;

interface Props {
  isOpen: boolean;
}

defineProps<Props>();

interface Emits {
  (e: 'updateFilters') : void;
  (e: 'close'): void;
}

const emit = defineEmits<Emits>();

const webApp = inject<WebApp>('webApp');
const cuponstarCategories = inject<CuponstarCategory[]>('cuponstarCategories');

const webAppId = computed(() => webApp?.id || 0);

const showIncluded = ref(true);
const showRedeemable = ref(true);

const {
  filters,
} = useWebAppCouponsQuery(
  webAppId.value,
);

const discountFilter = ref([DEFAULT_MIN_DISCOUNT, DEFAULT_MAX_DISCOUNT]);
const pointCostFilter = ref([DEFAULT_MIN_POINT_COST, DEFAULT_MAX_POINT_COST]);

watch(discountFilter, (value) => {
  filters.couponNumericDiscountGteq = value[0];
  filters.couponNumericDiscountLteq = value[1];
});

watch(pointCostFilter, (value) => {
  filters.pointCostGteq = value[0];
  filters.pointCostLteq = value[1];
});

watch([showIncluded, showRedeemable], ([showIncludedValue, showRedeemableValue]) => {
  if (showIncludedValue === showRedeemableValue) {
    filters.pointCostEq = undefined;
    filters.pointCostGt = undefined;
  } else {
    filters.pointCostEq = showIncludedValue ? 0 : undefined;
    filters.pointCostGt = showIncludedValue ? undefined : 0;
  }
});

</script>
<template>
  <base-modal
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template #content>
      <div>
        <h3 class="text-2xl font-bold">
          {{ $t('functionalities.coupons.filters.title') }}
        </h3>
        <div class="mt-6 flex flex-col gap-4">
          <div class="flex flex-col gap-4">
            <base-checkbox-input
              v-model="showIncluded"
              class="text-sm"
              :label="$t('functionalities.coupons.filters.included')"
              :value="true"
            />
            <base-checkbox-input
              v-model="showRedeemable"
              class="text-sm"
              :label="$t('functionalities.coupons.filters.redeemable')"
              :value="true"
            />
          </div>
          <div>
            <p class="text-sm font-bold">
              {{ $t('functionalities.coupons.filters.byPointCost') }}
            </p>
            <base-slider
              v-model="pointCostFilter"
              name="pointCost"
              :min="DEFAULT_MIN_POINT_COST"
              :max="DEFAULT_MAX_POINT_COST"
              :step="100"
            />
          </div>
          <div>
            <p class="text-sm font-bold">
              {{ $t('functionalities.coupons.filters.byDiscounts') }}
            </p>
            <div class="mt-2 flex gap-2">
              <base-switch
                v-model="filters.couponNumericDiscountNull"
                name="numericDiscountNull"
              />
              <p class="text-sm">
                {{ $t('functionalities.coupons.filters.withoutNumericDiscount') }}
              </p>
            </div>
            <base-slider
              v-if="!filters.couponNumericDiscountNull"
              v-model="discountFilter"
              name="discount"
            />
          </div>
          <div>
            <p class="text-sm font-bold">
              {{ $t('functionalities.coupons.filters.byCategories') }}
            </p>
            <div class="mt-5 flex flex-col gap-5 md:grid md:grid-cols-5">
              <base-checkbox-input
                v-for="category in cuponstarCategories"
                :key="category.id"
                v-model="filters.cuponstarCategoriesIdIn"
                name="cuponstarCategoriesIdIn"
                :value="category.id"
                :label="category.name"
                class="text-xs"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #actions>
      <base-button
        theme="primary"
        :label="$t('actions.apply')"
        @click="emit('updateFilters')"
      />
    </template>
  </base-modal>
</template>
