import { ref, computed } from 'vue';
import { useMutation } from 'vue-query';

import { create } from '@/api/section';
import type { SectionBaseTypeName } from '@/types/section-base-type-name';

export function useSectionCreate(props: { webAppId: number }) {
  const showCreateModal = ref(false);

  function openCreateModal() {
    showCreateModal.value = true;
  }

  function closeCreateModal() {
    showCreateModal.value = false;
  }

  const {
    mutate: createSection,
    isError,
    isLoading,
    isSuccess,
  } = useMutation(
    (baseType: SectionBaseTypeName) => create({ baseType, webAppId: props.webAppId }),
    {
      onSuccess: (data) => {
        closeCreateModal();
        window.location.href = data.section.path;
      },
      onError: () => {
        closeCreateModal();
      },
    },
  );

  const showLoading = computed(() => (isLoading.value || isSuccess.value));

  return {
    showCreateModal,
    openCreateModal,
    closeCreateModal,
    createSection,
    isCreateLoading: showLoading,
    isCreateError: isError,
  };
}
