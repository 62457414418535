import { ref } from 'vue';

const toggledDropdownId = ref<number | null>(null);

export default function useSharedToggleDropdown() {
  function toggleDropdown(id: number) {
    if (toggledDropdownId.value === id) {
      toggledDropdownId.value = null;
    } else {
      toggledDropdownId.value = id;
    }
  }

  return { toggledDropdownId, toggleDropdown };
}
