import { serialize } from 'object-to-formdata';

import type { WebAppCountryService } from '@/types/web-app-country-service';
import type { WebAppCountryServiceForm } from '@/types/web-app-country-service-form';

import api from './index';

const BASE_WEB_APP_PATH = '/api/internal/web_apps';
const BASE_PATH = '/api/internal/web_app_country_services';

export default {
  async index(webAppId: number): Promise<WebAppCountryService[]> {
    const path = `${BASE_WEB_APP_PATH}/${webAppId}/web_app_country_services`;

    const response = await api({
      method: 'get',
      url: path,
    });

    return response.data.webAppCountryServices as WebAppCountryService[];
  },
  update(webAppCountryServiceId: number, webAppCountryService: WebAppCountryServiceForm) {
    const path = `${BASE_PATH}/${webAppCountryServiceId}`;

    return api({
      method: 'put',
      url: path,
      data: serialize({ webAppCountryService }),
    });
  },
  delete(webAppCountryServiceId: number) {
    const path = `${BASE_PATH}/${webAppCountryServiceId}`;

    return api({
      method: 'delete',
      url: path,
    });
  },
};
