<script setup lang="ts">
import { computed, useAttrs } from 'vue';

type Variant = 'light-gray';
type LabelVisibility = 'lg-visible';

interface Props {
  iconName: string;
  variant: Variant;
  label: string;
  labelVisibility: LabelVisibility;
}

const props = defineProps<Props>();

const labelStyles = {
  'light-gray': 'text-xs text-gray-500',
};

const labelVisibilities = {
  'lg-visible': 'hidden lg:flex',
};

const iconStyles = {
  'primary': 'text-primary-600 bg-primary-200 rounded-full',
  'light-gray': 'text-gray-600 bg-gray-200 rounded-full',
};

const attrs = useAttrs();
const labelStyle = computed(() => labelStyles[props.variant]);
const labelVisibility = computed(() => labelVisibilities[props.labelVisibility]);
const iconStyle = computed(() => iconStyles[props.variant]);
const currentTag = computed(() => (attrs.href ? 'a' : 'button'));

</script>
<template>
  <component
    :is="currentTag"
    class="flex max-w-max items-center space-x-2"
  >
    <div
      class="flex aspect-square h-6 items-center justify-center  rounded-full p-1.5 md:h-7 md:p-2 2xl:h-8 2xl:px-2"
      :class="iconStyle"
    >
      <base-svg
        :name="iconName"
        class="h-full w-full stroke-current"
      />
    </div>
    <span
      v-if="label"
      :class="[labelStyle, labelVisibility]"
    >
      {{ label }}
    </span>
  </component>
</template>
