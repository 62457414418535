<script setup lang="ts">
import regexPatterns from '@/values/regex-patterns';

interface Props {
  modelValue?: File;
  label?: string;
  name: string;
  required?: boolean;
  size?: 'xs' | 'sm';
  weight?: 'bold' | 'normal';
  previewUrl?: string;
  previewName?: string;
  alignImage?: 'start' | 'center';
}
defineProps<Props>();

const emit = defineEmits<{(e: 'update:modelValue', value: File | undefined): void }>();

async function replaceFillsWithCurrentColor(file: File) {
  const fileText = await file.text();
  const fill = ' fill="currentColor"';
  const fixedFileText = fileText.replaceAll(
    regexPatterns.svgPathFill, fill,
  ).replaceAll(regexPatterns.svgGFill, fill);
  const fixedFile = new File([fixedFileText], file.name, { type: file.type });

  return fixedFile;
}

async function fixCurrentColorAndEmit(file: File | undefined) {
  if (file) {
    const fixedFile = await replaceFillsWithCurrentColor(file);
    emit('update:modelValue', fixedFile);
  } else {
    emit('update:modelValue', undefined);
  }
}
</script>

<template>
  <base-image-input
    :model-value="modelValue"
    :label="label"
    :name="name"
    :required="required"
    :size="size"
    :weight="weight"
    :preview-url="previewUrl"
    :preview-name="previewName"
    :align-image="alignImage"
    accept="image/svg+xml"
    :image-file-type-label="$t('common.svgFileType')"
    @update:model-value="fixCurrentColorAndEmit"
  />
</template>
