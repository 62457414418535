<script setup lang="ts">
import { useField } from 'vee-validate';

import type { SelectOption } from '@/types/select-option';

interface Props {
  modelValue?: string | number | SelectOption;
  options: SelectOption[];
  name: string;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
});

function isOptionSelected(option: SelectOption) {
  return option.id === props.modelValue;
}

const {
  handleChange,
} = useField(props.name);

const emit = defineEmits<{(e: 'update:modelValue', value: string | number): void}>();

function handleOptionChange(option: SelectOption) {
  handleChange(option.id);
  emit('update:modelValue', option.id);
}
</script>
<template>
  <div
    class="flex w-full flex-row rounded-3xl outline outline-1 outline-offset-2 outline-gray-300"
  >
    <button
      v-for="option in options"
      :key="option.id"
      type="button"
      data-testid="base-button-group-button"
      class="flex h-8 w-full items-center justify-center gap-2 rounded-3xl py-2 font-semibold"
      :class="{
        'bg-primary-200 text-primary-800': isOptionSelected(option),
        'text-gray-400': !isOptionSelected(option),
      }"
      @click.prevent="handleOptionChange(option)"
    >
      <base-svg
        v-if="option.icon"
        :name="option.icon"
      />
      <span class="font-medium">
        {{ option.name }}
      </span>
    </button>
  </div>
</template>
