import { computed, reactive } from 'vue';
import { useQuery } from 'vue-query';

import webAppCouponsApi from '@/api/webAppCoupon';
import type { WebAppCoupon } from '@/types/web-app-coupon';
import type { WebAppCouponFilters } from '@/types/web-app-coupon-filters';
import { createQueryParams } from '@/utils/query-params';

const filters = reactive<WebAppCouponFilters>({
  couponNameCont: undefined,
  couponNumericDiscountGteq: undefined,
  couponNumericDiscountLteq: undefined,
  couponNumericDiscountNull: undefined,
  pointCostEq: undefined,
  pointCostGt: undefined,
  pointCostGteq: undefined,
  pointCostLteq: undefined,
  cuponstarCategoriesIdIn: [],
});

export default function useWebAppCouponsQuery(
  webAppId: number,
  initialWebAppCoupons?: WebAppCoupon[],
) {
  const queryParams = computed(() => {
    if (filters.couponNumericDiscountNull) {
      return createQueryParams(
        { ...filters,
          couponNumericDiscountGteq: undefined,
          couponNumericDiscountLteq: undefined,
        },
      );
    }

    return createQueryParams(filters);
  });

  const { data: webAppCoupons, refetch } = useQuery(['web-app-coupons', webAppId],
    () => (webAppCouponsApi.index(webAppId, queryParams.value)),
    { initialData: initialWebAppCoupons,
      enabled: !!webAppId, retry: false },
  );

  return { webAppCoupons, filters, refetch };
}
