import { computed, reactive } from 'vue';
import { useQuery } from 'vue-query';

import couponsApi from '@/api/coupon';
import type { Coupon } from '@/types/coupon';
import type { CouponFilters } from '@/types/coupon-filters';
import { createQueryParams } from '@/utils/query-params';

const filters = reactive<CouponFilters>({
  nameCont: undefined,
  numericDiscountGteq: undefined,
  numericDiscountLteq: undefined,
  numericDiscountNull: undefined,
  cuponstarCategoriesIdIn: [],
});

export default function useCouponsQuery(
  webAppId: number,
  initialCoupons?: Coupon[],
) {
  const queryParams = computed(() => {
    if (filters.numericDiscountNull) {
      return createQueryParams(
        { ...filters,
          numericDiscountNull: 1,
          numericDiscountGteq: undefined,
          numericDiscountLteq: undefined,
        },
      );
    }

    return createQueryParams(filters);
  });

  const { data: coupons, refetch } = useQuery(['coupons', webAppId],
    () => (couponsApi.index(webAppId, queryParams.value)),
    { initialData: initialCoupons,
      enabled: !!webAppId, retry: false },
  );

  return { coupons, filters, refetch };
}
