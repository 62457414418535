import { useQuery } from 'vue-query';

import webAppCountryServicesApi from '@/api/webAppCountryService';
import type { WebAppCountryService } from '@/types/web-app-country-service';

export default function useWebAppCountryServicesQuery(
  webAppId: number,
  webAppCountryServices?: WebAppCountryService[],
) {
  return useQuery(['web-app-country-services', webAppId],
    () => (webAppCountryServicesApi.index(webAppId)),
    { initialData: webAppCountryServices,
      enabled: false, retry: false },
  );
}
