<script setup lang="ts">
import { Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption } from '@headlessui/vue';
import { useField } from 'vee-validate';
import { useAttrs, computed } from 'vue';

import useAttrsWithoutClass from '@/composables/useAttrsWithoutClass';
import useFieldError from '@/composables/useFieldError';
import type { SelectOption } from '@/types/select-option';

const labelSizeClass = {
  xs: 'text-xs',
  sm: 'text-sm',
};
const labelWeightClass = {
  semibold: 'font-semibold',
  bold: 'font-bold',
  normal: 'font-normal',
};

const selectSizeClass = {
  xs: 'h-8 text-xs',
  sm: 'h-10 text-xs md:text-sm',
};

interface SelectOptionsById {
  [key: string]: string;
}

interface Props {
  modelValue?: string | number | SelectOption;
  modelKey?: 'id' | 'name';
  options: SelectOption[];
  label?: string;
  name: string;
  size?: keyof typeof labelSizeClass;
  /** The font weight of the label. */
  weight?: keyof typeof labelWeightClass;
  allowEmpty?: boolean;
  emptyLabel?: string;
  warning?: string;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  modelKey: undefined,
  label: undefined,
  size: 'sm',
  weight: 'normal',
  allowEmpty: false,
  emptyLabel: undefined,
  warning: undefined,
});

const emit = defineEmits<{(e: 'update:modelValue', value: string | number | SelectOption): void}>();
const attrs = useAttrs();
const attrsWithoutClass = useAttrsWithoutClass();

const selectOptions = computed(() => {
  if (!props.allowEmpty) return props.options;
  const emptyOption: SelectOption = { id: 0, name: props.emptyLabel };

  return [emptyOption, ...props.options];
});

const defaultInitialValue = computed(() => {
  const firstOption = selectOptions.value[0];

  return props.modelKey ? firstOption[props.modelKey] : firstOption;
});

const initialValue = computed(() => (props.modelValue ?? defaultInitialValue.value));
emit('update:modelValue', initialValue.value);

const {
  value: selectedOption,
  errorMessage,
  meta,
  handleBlur,
  handleChange,
} = useField(props.name, undefined, {
  initialValue,
});

const { showError, hideError } = useFieldError({ errorMessage, meta });

function calculateOptionValue(option: SelectOption) {
  return props.modelKey ? option[props.modelKey] : option;
}

function handleInputChange(e: string | number | SelectOption) {
  handleChange(e);
  emit('update:modelValue', e);
}

const isDisabled = computed(() => attrs.disabled === '' || attrs.disabled);

const selectOptionsById = computed(() => (selectOptions.value
  .reduce((hash, selectOption) => {
    hash[selectOption.id.toString()] = selectOption.name;

    return hash;
  }, {} as SelectOptionsById)
));

const selectedOptionName = computed(() => {
  if (props.modelKey === 'name') return selectedOption.value as string;
  if (props.modelKey === 'id') return selectOptionsById.value[selectedOption.value.toString()];

  return (selectedOption.value as SelectOption).name;
});

</script>
<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<template>
  <div class="flex flex-col space-y-1">
    <label
      v-if="!!label"
      :for="name"
      class="text-gray-700"
      :class="[labelSizeClass[size], labelWeightClass[weight]]"
    >
      {{ label }}
      <p
        v-show="warning"
        class="text-left text-xs text-primary-700"
      >
        &#9888; {{ warning }}
      </p>
    </label>
    <Listbox
      :id="name"
      v-model="selectedOption"
      class="relative flex-col"
      as="div"
      :data-testid="name"
      @update:model-value="handleInputChange"
      @blur="handleBlur"
    >
      <ListboxButton
        v-bind="attrsWithoutClass"
        class="flex h-8 w-full items-center justify-between rounded-md border border-gray-300 px-2 text-left text-gray-700"
        :class="[{
          'border-error-600': showError,
          'border-gray-300': hideError,
          'bg-gray-50': isDisabled,
        }, selectSizeClass[size]]"
      >
        <span>
          {{ selectedOptionName }}
        </span>
        <base-svg
          name="arrow-down"
          class="h-2 fill-current text-gray-500"
        />
      </ListboxButton>
      <ListboxOptions class="absolute z-10 max-h-32 w-full overflow-y-auto rounded-b-md bg-gray-100 shadow-md">
        <ListboxOption
          v-for="(option, optionIndex) in selectOptions"
          :key="option.id"
          class="w-full border-gray-200 px-2 py-1 text-left text-gray-700"
          :class="[{'border-b': optionIndex!== selectOptions.length -1 }, selectSizeClass[size]]"
          :value="calculateOptionValue(option)"
        >
          {{ option.name }}
        </ListboxOption>
      </ListboxOptions>
    </Listbox>
    <p
      v-show="showError"
      class="text-left text-xs text-error-600"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
