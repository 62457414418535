import { ref, computed } from 'vue';
import { useMutation } from 'vue-query';

import { destroy } from '@/api/section';

export function useSectionDestroy(props: { sectionId: number, successRedirectPath: string }) {
  const showDestroyModal = ref(false);

  function openDestroyModal() {
    showDestroyModal.value = true;
  }

  function closeDestroyModal() {
    showDestroyModal.value = false;
  }

  const {
    mutate: destroySection,
    isError,
    isLoading,
    isSuccess,
  } = useMutation(
    () => destroy(props.sectionId),
    {
      onSuccess: () => {
        closeDestroyModal();
        window.location.href = props.successRedirectPath;
      },
      onError: () => {
        closeDestroyModal();
      },
    },
  );

  const showLoading = computed(() => (isLoading.value || isSuccess.value));

  return {
    showDestroyModal,
    openDestroyModal,
    closeDestroyModal,
    destroySection,
    isLoading: showLoading,
    isError,
  };
}
