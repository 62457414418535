import { camelize, camelizeKeys } from 'humps';

import type { Section } from '@/types/section';

type UncamelizedSection = Omit<Section, 'type' | 'baseType'> & {
  type: string;
  baseType: string;
};

export function camelizeSection(section: UncamelizedSection): Section {
  const camelizedSection : Section = {
    ...section,
    type: camelize(section.type),
    baseType: camelize(section.baseType),
  };

  return camelizedSection;
}

export function camelizeSectionKeys(section: Record<string, unknown>) : Section {
  return camelizeSection(camelizeKeys(section));
}

export function camelizeSectionsKeys(sections: Record<string, unknown>[]) : Section[] {
  return sections.map(camelizeSectionKeys);
}
