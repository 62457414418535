<script setup lang="ts">
import { inject, computed } from 'vue';

import type { ColorOption } from '@/types/color-option';
import type { DesignConfig } from '@/types/design-config';
import { getSectionFieldLabel } from '@/utils/get-section-field-translations';

import ColorSectionFieldInput from './color-section-field-input.vue';

export type Props = {
  name: string;
  baseType: string;
  modelValue?: ColorOption;
}

const props = defineProps<Props>();
const emit = defineEmits<{(e: 'update:modelValue', value: ColorOption | undefined): void;
}>();

const label = getSectionFieldLabel(props.baseType, props.name);
const designConfig = inject<DesignConfig | undefined>('designConfig');

const colors = computed(() => {
  if (!designConfig) return {} as Partial<Record<ColorOption, string>>;

  return {
    primary: designConfig.primaryColor,
    secondary: designConfig.secondaryColor,
  };
});

function handleUpdateModelValue(value: ColorOption | undefined) {
  emit('update:modelValue', value);
}
</script>

<template>
  <color-section-field-input
    :name="props.name"
    :label="label"
    :model-value="props.modelValue"
    :colors="colors"
    @update:model-value="handleUpdateModelValue"
  />
</template>
