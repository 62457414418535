<script setup lang="ts">
import { computed, defineEmits } from 'vue';

import type { Service } from '@/types/service';

interface Props {
  item: Service;
  hasLoyalty: boolean;
}

const props = defineProps<Props>();

interface Emits {
  (event: 'show'): void,
  (event: 'edit'): void,
}

const emit = defineEmits<Emits>();

const withoutLoyalty = computed(() => !props.hasLoyalty);

</script>
<template>
  <div class="grid grid-cols-4 items-center border border-r-0 border-gray-200 bg-white px-8 py-2 md:border-r">
    <div class="col-span-2 flex items-center">
      <base-icon-button
        variant="primary"
        icon-name="shopping-bag"
        label-visibility="lg-visible"
      />
      <p class="ml-2">
        {{ item.name }}
      </p>
    </div>
    <div
      class="relative col-span-2 grid"
      :class="{'grid-cols-3': hasLoyalty, 'grid-cols-2': withoutLoyalty}"
    >
      <div
        v-if="hasLoyalty"
        class="col-span-1 flex items-center justify-start"
      >
        <div
          v-if="item.pointCost > 0"
          class="w-fit rounded-xl bg-blue-100 px-2 py-0.5 text-center text-xs text-blue-800"
        >
          {{ $t("salesServices.fields.serviceLoyaltyTypes.redeemable.label") }}
        </div>
        <div
          v-else
          class="w-fit rounded-xl  bg-success-100 px-2 py-0.5 text-center text-xs text-success-800"
        >
          {{ $t("salesServices.fields.serviceLoyaltyTypes.included.label") }}
        </div>
      </div>
      <base-icon-button
        variant="light-gray"
        icon-name="eye"
        :label="$t('actions.show')"
        label-visibility="lg-visible"
        @click="() => emit('show')"
      />
      <base-icon-button
        variant="light-gray"
        icon-name="gear"
        :label="$t('actions.customize')"
        label-visibility="lg-visible"
        @click="() => emit('edit')"
      />
    </div>
  </div>
</template>
