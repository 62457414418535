<script setup lang="ts">
import { useField } from 'vee-validate';

import useAttrsWithoutClass from '@/composables/useAttrsWithoutClass';

interface Props {
  modelValue?: string;
  name: string;
  size?: string;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  size: 'sm',
});

const attrsWithoutClass = useAttrsWithoutClass();

const emit = defineEmits<{(e: 'update:modelValue', value: string): void}>();

const {
  value: inputValue,
  handleBlur,
  handleChange,
} = useField(props.name, undefined, {
  initialValue: props.modelValue,
});

const inputSizeClass = {
  xs: 'h-8 placeholder:text-xs',
  sm: 'h-10 placeholder:text-sm',
};

function handleInputChange(e: Event) {
  handleChange(e);
  const input = e.target as HTMLInputElement;
  emit('update:modelValue', input.value);
}

</script>
<script lang="ts">
export default {
  inheritAttrs: true,
};
</script>
<template>
  <div class="flex flex-col space-y-1">
    <div class="relative flex flex-row">
      <input
        :id="name"
        :value="inputValue"
        class="h-11 w-full rounded-lg border border-gray-300 p-3 pl-11 text-gray-700 outline-gray-600 placeholder:text-slate-400"
        :class="inputSizeClass[size as keyof typeof inputSizeClass]"
        v-bind="attrsWithoutClass"
        @input="handleInputChange"
        @blur="handleBlur"
      >
      <div class="absolute left-3">
        <base-svg
          name="search"
          class="my-3 h-5 fill-current text-slate-400"
        />
      </div>
    </div>
  </div>
</template>
