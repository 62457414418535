<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMutation } from 'vue-query';
import * as yup from 'yup';

import benefitGroupApi from '@/api/benefitGroup';
import { BenefitGroup } from '@/types/benefit-group';
import { BenefitGroupForm } from '@/types/benefit-group-form';

interface Props {
  benefitGroup?: BenefitGroup;
  open: boolean;
  webAppId: number;
  benefitGroupsNeedImageAndDescription: boolean;
}
const props = defineProps<Props>();
interface Emits {
  (event: 'close'): void;
  (event: 'save') : void;
}
const emit = defineEmits<Emits>();

const initialData = {
  name: props.benefitGroup?.name || '',
  description: props.benefitGroup?.description || '',
  image: undefined,
  icon: undefined,
};

const { t } = useI18n();
const benefitGroupSchema = computed(() => {
  const shape: yup.ObjectShape = {
    name: yup.string().required().label(t('benefitGroups.fields.name')),
  };

  if (!props.benefitGroup?.iconUrl) {
    shape.icon = yup.mixed().required().label(t('benefitGroups.fields.icon'));
  }
  if (props.benefitGroupsNeedImageAndDescription) {
    if (!props.benefitGroup?.imageUrl) {
      shape.image = yup.mixed().required().label(t('benefitGroups.fields.image'));
    }
    shape.description = yup.string().required().label(t('benefitGroups.fields.description'));
  }

  return yup.object().shape(shape);
});

function saveBenefitGroupRequest(values: BenefitGroupForm) {
  if (props.benefitGroup) {
    return benefitGroupApi.update(props.benefitGroup.id, values);
  }

  return benefitGroupApi.create(props.webAppId, values);
}
const { mutate: saveBenefitGroup, isError, isLoading } = useMutation(
  saveBenefitGroupRequest,
  { onSuccess: () => emit('save') },
);

function close() {
  emit('close');
}

const iconGalleryUrl = import.meta.env.VITE_ICON_GALLERY_URL;
</script>

<template>
  <base-modal
    :is-open="open"
    @close="close"
  >
    <template #content>
      <div class="flex flex-1 flex-col overflow-y-hidden">
        <div class="flex items-center gap-2 border-b border-gray-200 pb-6">
          <div class="flex h-8 w-8 items-center justify-center rounded-full bg-primary-100">
            <base-svg
              class="h-6 w-6 stroke-primary-700"
              name="clipboard"
            />
          </div>
          <h2 class="text-lg font-semibold text-gray-900">
            {{ $t('benefitGroups.addGroupTitle') }}
          </h2>
        </div>
        <v-form
          v-slot="{ meta }"
          validate-on-mount
          :validation-schema="benefitGroupSchema"
          :initial-values="initialData"
          class="flex flex-col overflow-y-auto"
          @submit="saveBenefitGroup"
        >
          <div class="flex-1 space-y-4 overflow-y-auto py-6">
            <span class="text-sm">
              {{ $t('benefitGroups.addGroupSubtitle') }}
            </span>
            <base-error
              v-if="isError"
              :message="$t('benefitGroups.errors.save')"
            />
            <base-input
              data-testid="name-input"
              :label="$t('benefitGroups.fields.name')"
              :placeholder="$t('benefitGroups.fields.name')"
              name="name"
            />
            <base-rich-input
              v-if="benefitGroupsNeedImageAndDescription"
              data-testid="description-input"
              :label="$t('benefitGroups.fields.description')"
              name="description"
              weight="bold"
              :options="['bold', 'italic', 'bulletList', 'undo', 'redo']"
            />
            <div>
              <label
                for="icon"
                class="m-1 text-sm text-gray-700"
              >
                <i18n-t
                  keypath="benefitGroups.fields.uploadIcon"
                >
                  <template #iconGaleryLinkText>
                    <a
                      class="text-primary-700 underline"
                      :href="iconGalleryUrl"
                      target="_blank"
                    >
                      {{ $t('benefitGroups.fields.iconGaleryLinkText') }}
                    </a>
                  </template>
                </i18n-t>
              </label>
              <base-svg-input
                data-testid="icon-input"
                name="icon"
                :preview-url="benefitGroup?.iconUrl"
                :preview-name="benefitGroup?.iconFilename"
              />
            </div>
            <base-image-input
              v-if="benefitGroupsNeedImageAndDescription"
              data-testid="image-input"
              :label="$t('benefitGroups.fields.uploadImage')"
              name="image"
              :preview-url="benefitGroup?.imageUrl"
              :preview-name="benefitGroup?.imageFilename"
            />
          </div>
          <div class="flex justify-end space-x-3 border-t border-gray-200 pt-6">
            <base-button
              type="button"
              theme="secondary"
              :label="$t('actions.cancel')"
              @click="close"
            />
            <base-button
              type="submit"
              theme="primary"
              :label="$t('actions.save')"
              :loading="isLoading"
              :disabled="isLoading || !meta.dirty"
            />
          </div>
        </v-form>
      </div>
    </template>
  </base-modal>
</template>
