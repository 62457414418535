import { useI18n } from 'vue-i18n';

export function getSectionFieldLabel(baseType: string, sectionFieldName: string) {
  const { t } = useI18n({});

  return t(`sections.${baseType}.sectionFields.${sectionFieldName}`);
}

export function getSectionFieldDescription(baseType: string, sectionFieldName: string) {
  const { t } = useI18n({});

  return t(`sections.${baseType}.sectionFieldsDescriptions.${sectionFieldName}`);
}
