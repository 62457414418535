<script setup lang="ts">
import type { Editor } from '@tiptap/vue-3';
import { computed } from 'vue';

import getToolbarButtons from '@/utils/get-toolbar-buttons';

interface Props {
  editor?: Editor;
  titleLevel: number;
  colors: string[];
  options: string[];
}

const props = withDefaults(defineProps<Props>(), {
  editor: undefined,
});

const buttons = computed(() => getToolbarButtons(props.editor, props.titleLevel, props.colors, props.options));

</script>
<template>
  <div
    ref="toolbar"
    class="mb-2 flex flex-wrap gap-x-1"
  >
    <base-button
      v-for="button in buttons"
      :key="button.name"
      v-tooltip="button.tooltip"
      type="button"
      theme="gray"
      :icon-file-name="button.iconFileName"
      size="sm"
      :style="button.style"
      @click="button.onClick()"
    />
  </div>
</template>
