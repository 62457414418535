<script setup lang="ts">
import { useField } from 'vee-validate';
import { toRef } from 'vue';

type Props = {
  modelValue?: boolean | unknown[];
  value: unknown;
  name: string;
  label?: string;
  disabled?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  label: undefined,
  disabled: false,
});

const name = toRef(props, 'name');

const { handleChange, checked } = useField(name, undefined, {
  type: 'checkbox',
  checkedValue: props.value,
  initialValue: props.modelValue,
});
</script>
<template>
  <div class="flex items-center gap-2">
    <input
      :checked="checked"
      :value="value"
      type="checkbox"
      :name="name"
      :disabled="disabled"
      class="h-5 w-5 rounded-sm checked:border-primary-700 checked:text-primary-700 focus:ring-transparent disabled:opacity-50"
      @change="handleChange"
    >
    <label
      v-if="label"
      :for="name"
      class="flex items-center border-gray-500 text-gray-800"
    >
      {{ label }}
    </label>
  </div>
</template>
