<script setup lang="ts">
import { RadioGroupOption } from '@headlessui/vue';
import { kebabCase } from 'lodash';
import { computed } from 'vue';

export type Props = {
  name: string;
  sectionBaseType: string;
}

const props = defineProps<Props>();

const iconName = computed(() => (`sections-${kebabCase(props.name)}`));

</script>
<template>
  <RadioGroupOption
    v-slot="{ checked }"
    :value="name"
    class="flex grow"
  >
    <div
      class="flex w-full grow flex-col rounded border-2 px-4 py-2.5"
      :class="{
        'border-primary-700 bg-primary-50 font-medium text-primary-700': checked,
        'border-gray-300 text-gray-500': !checked
      }"
    >
      <div class="flex justify-between">
        <p class="text-xs">
          {{ $t(`sections.${sectionBaseType}.variants.${props.name}`) }}
        </p>
        <div
          class="flex aspect-square h-4 w-4 items-center justify-center rounded-full border"
          :class="{ 'border-primary-700': checked,'border-gray-400': !checked}"
        >
          <base-svg
            v-if="checked"
            data-testid="checked-icon"
            class="h-2.5"
            name="circle"
          />
        </div>
      </div>
      <div class="flex py-2">
        <base-svg
          data-testid="option-icon"
          :name="iconName"
          class="w-full fill-current stroke-current"
          :class="{
            'fill-primary-500 stroke-primary-500' : checked,
            'fill-gray-400 stroke-gray-400': !checked
          }"
        />
      </div>
    </div>
  </RadioGroupOption>
</template>
