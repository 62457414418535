<script setup lang="ts">
import { computed } from 'vue';

import type { CountryService } from '@/types/country-service';

const props = defineProps<{
  countryService: CountryService;
  actions:('show' | 'add' | 'delete')[];
}>();

defineEmits<{(e: 'onAdd', id: number): void;
  (e: 'onShow', id: number): void;
  (e: 'onDelete', id: number): void;
}>();

const add = computed(() => props.actions.includes('add'));
const show = computed(() => props.actions.includes('show'));
const remove = computed(() => props.actions.includes('delete'));
</script>
<template>
  <div
    class="flex items-center justify-between border border-gray-200 px-5 py-4 md:px-8"
  >
    <div class="text-gray-800">
      {{ countryService.name }}
    </div>
    <div class="flex items-center text-gray-600">
      <base-button
        v-if="add"
        theme="primary"
        data-testid="add-service"
        icon-file-name="add"
        :label="$t('actions.add')"
        @click="$emit('onAdd', countryService.id)"
      />
      <base-button
        v-if="show"
        icon-file-name="eye"
        :label="$t('actions.show')"
        @click="$emit('onShow', countryService.id)"
      />
      <base-button
        v-if="remove"
        icon-file-name="bin"
        :label="$t('actions.delete')"
        @click="$emit('onDelete', countryService.id)"
      />
    </div>
  </div>
</template>
