<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue';

import type { Category } from '@/types/category';
import type { CountryService } from '@/types/country-service';
import type { Service } from '@/types/service';
import type { WebAppCategory } from '@/types/web-app-category';
import type { WebAppCountryService } from '@/types/web-app-country-service';

import CountryServicesSectionCategory from './country-services-section-category.vue';

interface Props {
  family: Category;
  activeCategories: Category[];
  categories: Category[];
  webAppCategories: WebAppCategory[];
  webAppCountryServices: WebAppCountryService[];
  webAppSalesServices: Service[];
  webAppId: number;
  isSalesApp: boolean;
  hasLoyalty: boolean;
}

interface Emits {
  (event: 'show', webAppCountryService: WebAppCountryService | Service): void,
  (event: 'edit', webAppCountryService: WebAppCountryService): void,
  (event: 'editFamily', family: Category): void,
  (event: 'editCategory', category: Category): void,
  (event: 'createSalesService', category: Category): void,
  (event: 'editSalesService', salesService: Service): void,
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const missingCategories = computed(() => (
  props.categories.filter((category) => (
    !props.activeCategories.find((activeCategory) => (activeCategory.id === category.id))
  ))
));

const countryServices = inject<CountryService[]>('countryServices');

function countryServicesForCategory(categoryId: number) {
  return countryServices?.filter((countryService) => (countryService.categoryId === categoryId)) || [];
}

function salesServicesForCategory(categoryId: number) {
  return props.webAppSalesServices.filter(salesService => salesService.categoryId === categoryId);
}

const familyWebAppCategory = computed(() => (
  props.webAppCategories.find((webAppCategory) => (webAppCategory.categoryId === props.family.id))
));

const familyName = computed(() => familyWebAppCategory.value?.customName || props.family.name);
const familyIcon = computed(() => {
  const customIconUrl = familyWebAppCategory.value?.customIconUrl;

  if (customIconUrl) {
    return {
      src: customIconUrl,
    };
  }

  return {
    name: props.family.icon,
  };
});

const dropdownVisible = ref(false);
const iconDirection = computed(() => (dropdownVisible.value ? 'up' : 'down'));

const familyWebAppCountryServices = computed(() =>
  props.webAppCountryServices.filter(
    webAppCountryService => webAppCountryService.countryService.categoryId === props.family.id));

const familySalesServices = computed(() =>
  props.webAppSalesServices.filter(
    webAppSalesService => webAppSalesService.categoryId === props.family.id));

const missingFamilyCategory = computed(() =>
  countryServicesForCategory(props.family.id).length > 0 &&
    familyWebAppCountryServices.value.length === 0 &&
    familySalesServices.value.length === 0,
);

const toggledMissingFamily = ref(false);

function webAppCountryServicesForCategory(category: Category) {
  return props.webAppCountryServices.filter((webAppCountryService) =>
    (webAppCountryService.countryService.categoryId === category.id));
}

function webAppCategoryForCategory(category: Category) {
  return props.webAppCategories.find((webAppCategory) => (webAppCategory.categoryId === category.id));
}

const toggledMissingCategories = ref<boolean[]>([]);

watch(() => props.activeCategories.length, () => {
  toggledMissingCategories.value = Array(missingCategories.value.length).fill(false);
});

watch(() => familyWebAppCountryServices.value.length, () => {
  if (familyWebAppCountryServices.value.length > 0) {
    toggledMissingFamily.value = false;
  }
});
</script>
<template>
  <div class="grid grid-cols-3 items-center border">
    <div class="col-span-2 m-4 flex h-10 flex-wrap gap-1 align-middle md:mx-8 md:my-2">
      <base-svg
        v-bind="familyIcon"
        class="h-8 w-8 self-center fill-current pr-2 text-primary-700"
      />
      <p class="my-auto">
        {{ familyName }}
      </p>
      <button
        @click="emit('editFamily', family)"
      >
        <base-pill
          theme="edit"
          class=" order-3 my-auto h-5 md:order-2"
        >
          <span class="flex gap-2 align-middle">
            {{ $t("countryServices.actions.editFamily") }}
            <base-svg
              name="pencil"
              class="my-auto h-2.5 w-2.5 stroke-current"
            />
          </span>
        </base-pill>
      </button>
      <p class="my-auto text-xs text-gray-500">
        {{ $t("countryServices.info.numberOfCategories", {added: activeCategories.length, total: categories.length}) }}
      </p>
    </div>
    <div
      class="justify-self-end"
      data-testid="country-services-section-family-dropdown"
    >
      <a
        class="cursor-pointer"
        @click="dropdownVisible = !dropdownVisible"
      >
        <base-pill
          theme="dropdown"
          class="my-auto mr-4 hidden h-5 self-end md:block"
        >
          <span class="flex gap-2 align-middle">
            {{ $t("actions.more") }}
            <base-svg
              :name="`chevron-${iconDirection}`"
              class="my-auto h-2.5 w-2.5 fill-current"
            />
          </span>
        </base-pill>
        <base-svg
          :name="`chevron-${iconDirection}`"
          class="my-auto mr-4 h-2.5 w-2.5 fill-current md:hidden"
        />
      </a>
    </div>
    <div
      v-if="dropdownVisible"
      class="col-span-3 my-1 w-full bg-slate-50 md:my-2"
    >
      <div>
        <div
          v-if="familyWebAppCountryServices.length > 0 || familySalesServices.length > 0"
          class="flex flex-col md:px-2 md:pt-4"
        >
          <country-services-section-category
            :family="family"
            :web-app-country-services="familyWebAppCountryServices"
            :sales-services="familySalesServices"
            :country-services="countryServicesForCategory(props.family.id)"
            :web-app-id="props.webAppId"
            :is-sales-app="props.isSalesApp"
            :has-loyalty="hasLoyalty"
            @show="(webAppCountryService:WebAppCountryService | Service) => emit('show', webAppCountryService)"
            @edit="(webAppCountryService:WebAppCountryService) => emit('edit', webAppCountryService)"
            @create-sales-service="(family: Category) => emit('createSalesService', family)"
            @edit-sales-service="(salesService: Service) => emit('editSalesService', salesService)"
          />
          <div class="mt-4 h-1 bg-slate-200 md:h-px" />
        </div>
        <div
          v-for="category in activeCategories"
          :key="category.id"
          class="flex flex-col md:px-2 md:pt-4"
        >
          <country-services-section-category
            :category="category"
            :family="family"
            :web-app-country-services="webAppCountryServicesForCategory(category)"
            :web-app-category="webAppCategoryForCategory(category)"
            :country-services="countryServicesForCategory(category.id)"
            :sales-services="salesServicesForCategory(category.id)"
            :web-app-id="props.webAppId"
            :is-sales-app="props.isSalesApp"
            :has-loyalty="hasLoyalty"
            @show="(webAppCountryService:WebAppCountryService | Service) => emit('show', webAppCountryService)"
            @edit="(webAppCountryService:WebAppCountryService) => emit('edit', webAppCountryService)"
            @edit-category="() => emit('editCategory', category)"
            @create-sales-service="(category: Category) => emit('createSalesService', category)"
            @edit-sales-service="(salesService: Service) => emit('editSalesService', salesService)"
          />
          <div class="mt-4 h-1 bg-slate-200 md:h-px" />
        </div>
      </div>
      <div
        v-if="toggledMissingFamily"
        class="flex flex-col md:px-2 md:pt-4"
      >
        <country-services-section-category
          :family="family"
          :web-app-country-services="familyWebAppCountryServices"
          :country-services="countryServicesForCategory(props.family.id)"
          :web-app-id="props.webAppId"
          :has-loyalty="hasLoyalty"
          mode="new"
        />
      </div>
      <div
        v-for="(category, index) in missingCategories"
        :key="category.id"
      >
        <div
          v-if="toggledMissingCategories[index]"
          class="flex flex-col md:px-2 md:pt-4"
        >
          <country-services-section-category
            :category="category"
            :family="family"
            :web-app-country-services="webAppCountryServicesForCategory(category)"
            :web-app-category="webAppCategoryForCategory(category)"
            :country-services="countryServicesForCategory(category.id)"
            :web-app-id="props.webAppId"
            :has-loyalty="hasLoyalty"
            mode="new"
          />
        </div>
      </div>
      <div
        v-if="missingFamilyCategory || missingCategories.length > 0"
      >
        <div
          class="mx-3 mb-5 mt-6 flex gap-2 rounded-lg border border-orange-200 bg-orange-50 p-4 md:mx-20 md:mt-4"
        >
          <base-svg
            name="exclamation-triangle"
            class="h-6 w-6 text-warning-600"
          />
          <div class="flex w-full flex-col gap-2 text-xs">
            <p class="mb-3 font-medium">
              {{ $t('countryServices.info.missingCategories') }}
            </p>
            <div
              v-if="missingFamilyCategory"
              class="flex justify-between"
            >
              <p class="font-bold">
                {{ $t('countryServices.info.noCategory') }}
              </p>
              <base-switch
                v-model="toggledMissingFamily"
                :name="family.name"
              />
            </div>
            <div
              v-for="(category, index) in missingCategories"
              :key="category.id"
              class="flex justify-between"
            >
              <p class="font-bold">
                {{ category.name }}
              </p>
              <base-switch
                v-model="toggledMissingCategories[index]"
                :name="category.name"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
