<script setup lang="ts">
import isEqual from 'lodash/isEqual';
import { useForm } from 'vee-validate';
import { computed, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMutation } from 'vue-query';
import { object, string, boolean } from 'yup';

import webAppApi from '@/api/webApp';
import BaseMokButton from '@/components/base-mok-button.vue';
import type { EnvConfig } from '@/types/env-config';
import type { WebApp } from '@/types/web-app';
import type { WebAppTabs } from '@/types/web-app-tabs';

import BaseMokInput from './base-mok-input.vue';
import WebAppFeature from './web-app-feature.vue';
import WebAppFunctionalitiesLayout from './web-app-functionalities-layout.vue';

export type Props = {
  webApp: WebApp;
  envConfig: EnvConfig;
  backPath: string,
  webAppTabs: WebAppTabs;
  canEnableCoupons: boolean;
}

const props = defineProps<Props>();

const isUpdateSuccess = ref(false);
const isUpdateError = ref(false);

const { t } = useI18n({});

interface WebAppFunctionalitiesForm extends WebApp {
  envConfigAttributes: Partial<EnvConfig>
}

const webAppSchema = object({
  isConnectedToSso: boolean(),
  envConfigAttributes: object().when('isConnectedToSso', {
    is: true,
    then: () => object({
      ssoClientId: string().required().label(t('functionalities.fields.sso.ssoClientId')),
      ssoSecretKey: string().required().label(t('functionalities.fields.sso.ssoSecretKey')),
    }),
  }),
  hasLoyalty: boolean(),
  hasCoupons: boolean(),
  isTermsAndConditionsActive: boolean(),
  isPrivacyPolicyActive: boolean(),
  isNavigationBetweenWebAppsActive: boolean(),
});

const initialWebAppData = reactive<Partial<WebAppFunctionalitiesForm>>({
  isConnectedToSso: props.webApp.isConnectedToSso,
  envConfigAttributes: props.envConfig,
  hasLoyalty: props.webApp.hasLoyalty,
  hasCoupons: props.webApp.hasCoupons,
  isTermsAndConditionsActive: props.webApp.isTermsAndConditionsActive,
  isPrivacyPolicyActive: props.webApp.isPrivacyPolicyActive,
  isNavigationBetweenWebAppsActive: props.webApp.isNavigationBetweenWebAppsActive,
});

const {
  resetForm, values: webAppData, setValues: setWebAppData,
  validate, errors,
} = useForm<WebAppFunctionalitiesForm>(
  {
    initialValues: { ...initialWebAppData },
    validationSchema: webAppSchema,
    validateOnMount: true,
  },
);

const disabledActions = computed(() => (isEqual(webAppData, initialWebAppData)));
const ssoConnectionDisabled = computed(() => (!props.webApp.canConnectToSso));
const loyaltyConnectionDisabled = computed(() => (!webAppData.isConnectedToSso));

const {
  mutate: updateWebAppMutation,
  isLoading: isUpdateLoading,
} = useMutation(
  () => webAppApi.update(props.webApp.id, webAppData, false),
  {
    onSuccess: (response) => {
      isUpdateSuccess.value = true;
      setWebAppData(response.data.webApp);
      Object.assign(initialWebAppData, response.data.webApp);
    },
    onError: () => {
      isUpdateError.value = true;
    },
  },
);

async function updateWebApp() {
  await validate();
  if (Object.keys(errors.value).length !== 0) return;
  updateWebAppMutation();
}

watch(() => webAppData.isConnectedToSso, (newIsConnectedToSsso) => {
  if (!newIsConnectedToSsso) {
    setWebAppData({ hasLoyalty: false });
  }
});

</script>

<template>
  <web-app-functionalities-layout
    :web-app="webApp"
    :back-path="backPath"
    :web-app-tabs="webAppTabs"
    selected-tab="general"
    :is-update-success="isUpdateSuccess"
    :is-update-error="isUpdateError"
  >
    <form
      id="web-app-edit-form"
      @submit.prevent="updateWebApp"
    >
      <div class="flex w-full flex-col gap-2 gap-x-8 rounded-lg border border-gray-300 bg-white p-3 text-xs">
        <web-app-feature
          :title="$t('functionalities.fields.sso.title')"
          :description="$t('functionalities.fields.sso.description')"
          :disabled="ssoConnectionDisabled"
          :disabled-description="$t('functionalities.fields.sso.connectionDisabled')"
          data-testid="sso-feature"
        >
          <template #toggle>
            <base-switch
              v-model="webAppData.isConnectedToSso"
              :disabled="!webApp.canConnectToSso"
              name="isConnectedToSso"
              data-testid="sso-switch"
            />
          </template>
          <div class="grid w-full max-w-3xl grid-cols-2 items-stretch gap-x-8 px-3">
            <base-mok-input
              v-model="webAppData.envConfigAttributes.ssoClientId"
              name="envConfigAttributes.ssoClientId"
              :label="$t('functionalities.fields.sso.ssoClientId')"
              :placeholder="$t('functionalities.fields.sso.ssoClientId')"
              :disabled="ssoConnectionDisabled"
              data-testid="sso-client-id-input"
            />
            <base-mok-input
              v-model="webAppData.envConfigAttributes.ssoSecretKey"
              name="envConfigAttributes.ssoSecretKey"
              :label="$t('functionalities.fields.sso.ssoSecretKey')"
              :placeholder="$t('functionalities.fields.sso.ssoSecretKey')"
              :disabled="ssoConnectionDisabled"
              data-testid="sso-secret-key-input"
            />
          </div>
        </web-app-feature>
        <web-app-feature
          :title="$t('functionalities.fields.iLoyalty.title')"
          :description="$t('functionalities.fields.iLoyalty.description')"
          :disabled="loyaltyConnectionDisabled"
          :disabled-description="$t('functionalities.fields.iLoyalty.connectionDisabled')"
          data-testid="loyalty-feature"
        >
          <template #toggle>
            <base-switch
              v-model="webAppData.hasLoyalty"
              :disabled="!webAppData.isConnectedToSso"
              name="hasLoyalty"
              data-testid="loyalty-switch"
            />
          </template>
        </web-app-feature>
        <web-app-feature
          data-testid="coupons-feature"
          :title="$t('functionalities.fields.coupons.title')"
          :description="$t('functionalities.fields.coupons.description')"
          :disabled="!canEnableCoupons"
          :disabled-description="$t('functionalities.fields.coupons.connectionDisabled')"
        >
          <template #toggle>
            <base-switch
              v-if="canEnableCoupons"
              v-model="webAppData.hasCoupons"
              data-testid="coupons-switch"
              name="hasCoupons"
            />
          </template>
        </web-app-feature>
        <web-app-feature
          :title="$t('functionalities.fields.isNavigationBetweenWebAppsActive.title')"
          :description="$t('functionalities.fields.isNavigationBetweenWebAppsActive.description')"
          data-testid="navigation-between-web-apps-feature"
        >
          <template #toggle>
            <base-switch
              v-model="webAppData.isNavigationBetweenWebAppsActive"
              name="isNavigationBetweenWebAppsActive"
              data-testid="navigation-between-web-apps-switch"
            />
          </template>
        </web-app-feature>
        <web-app-feature
          :title="$t('functionalities.fields.termsAndConditions.title')"
          :description="$t('functionalities.fields.termsAndConditions.description')"
          :disabled="!webApp.canEnableTermsAndConditions"
          :disabled-description="$t('functionalities.fields.termsAndConditions.connectionDisabled')"
        >
          <template #toggle>
            <base-switch
              v-if="webApp.canEnableTermsAndConditions"
              v-model="webAppData.isTermsAndConditionsActive"
              name="isTermsAndConditionsActive"
            />
          </template>
        </web-app-feature>
        <web-app-feature
          :title="$t('functionalities.fields.privacyPolicy.title')"
          :description="$t('functionalities.fields.privacyPolicy.description')"
          :disabled="!webApp.canEnablePrivacyPolicy"
          :disabled-description="$t('functionalities.fields.privacyPolicy.connectionDisabled')"
        >
          <template #toggle>
            <base-switch
              v-if="webApp.canEnablePrivacyPolicy"
              v-model="webAppData.isPrivacyPolicyActive"
              name="isPrivacyPolicyActive"
            />
          </template>
        </web-app-feature>
      </div>
      <div class="mt-4 flex grow justify-end gap-4 text-sm md:text-base">
        <base-mok-button
          variant="secondary"
          :disabled="disabledActions"
          :label="$t('actions.cancel')"
          data-testid="cancel-button"
          @click="resetForm"
        />
        <base-mok-button
          variant="primary"
          :loading="isUpdateLoading"
          :label="$t('actions.saveChanges')"
          :disabled="disabledActions"
          data-testid="save-button"
        />
      </div>
    </form>
  </web-app-functionalities-layout>
</template>
