<script setup lang="ts">
import axios from 'axios';
import { defineAsyncComponent, computed, defineComponent } from 'vue';

const props = defineProps<{
  src?: string;
  name?: string;
}>();
function downloadSVG(url: string) {
  return axios
    .get(url)
    .then((response) => {
      const parser = new DOMParser();

      // eslint-disable-next-line vue/one-component-per-file
      return defineComponent({
        template: parser.parseFromString(response.data, 'image/svg+xml').documentElement.outerHTML,
      });
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);

      // eslint-disable-next-line vue/one-component-per-file
      return defineComponent({
        template: '<template></template>',
      });
    });
}
const svg = computed(() => {
  if (props.src) {
    return defineAsyncComponent(() => downloadSVG(props.src as string));
  } else if (props.name) {
    return defineAsyncComponent(() => import(`../../assets/images/${props.name}.svg`));
  }

  return undefined;
});
</script>

<template>
  <component :is="svg" />
</template>
