import { ref, watch, type Ref } from 'vue';

interface Props {
  condition: Ref<boolean>;
  timeout: number;
  reload?: boolean;
}

export default function useVisibilityTimeout(props: Props) {
  const isVisible = ref(props.condition.value);

  function setIsVisibleFalseAfterTimeout() {
    setTimeout(() => {
      isVisible.value = false;
      if (props.reload) window.location.reload();
    }, props.timeout);
  }

  if (isVisible.value) setIsVisibleFalseAfterTimeout();

  watch(props.condition, (newValue) => {
    isVisible.value = props.condition.value;
    if (newValue) setIsVisibleFalseAfterTimeout();
  });

  return { isVisible };
}
