<script setup lang="ts">
import { computed, type Component } from 'vue';

import type { Color } from '@/types/color';

type Shape = 'circle' | 'rounded';

export type Props = {
  variant?: 'primary' | 'secondary';
  icon: Component;
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  color?: Color;
  shape?: Shape;
}

type ColorClasses = {
  [key in Color]: string;
};

const props = withDefaults(defineProps<Props>(), {
  variant: 'primary',
  color: 'primary',
  shape: 'circle',
});

/* Force tailwind to include the possible classes in bundle */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const colorClasses : ColorClasses = {
  primary: 'fill-primary-700 fill-primary-600 bg-primary-100 border-primary-200',
  secondary: 'fill-secondary-700 fill-secondary-600 bg-secondary-100 border-secondary-200',
  error: 'fill-error-700 fill-error-600 bg-error-100 border-error-200',
  success: 'fill-success-700 fill-success-600 bg-success-100 border-success-200',
  warning: 'fill-warning-700 fill-warning-600 bg-warning-100 border-warning-200',
  gray: 'fill-gray-700 fill-gray-600 bg-gray-100 border-gray-200',
  'blue-gray': 'fill-blue-gray-700 fill-blue-gray-600 bg-blue-gray-100 border-blue-gray-200',
  'blue-light': 'fill-blue-light-700 fill-blue-light-600 bg-blue-light-100 border-blue-light-200',
  blue: 'fill-blue-700 fill-blue-600 bg-blue-100 border-blue-200',
  indigo: 'fill-indigo-700 fill-indigo-600 bg-indigo-100 border-indigo-200',
  purple: 'fill-purple-700 fill-purple-600 bg-purple-100 border-purple-200',
  pink: 'fill-pink-700 fill-pink-600 bg-pink-100 border-pink-200',
  rose: 'fill-rose-700 fill-rose-600 bg-rose-100 border-rose-200',
  orange: 'fill-orange-700 fill-orange-600 bg-orange-100 border-orange-200',
};

const iconContainerSizeStyles = {
  xs: 'h-4',
  sm: 'h-6',
  md: 'h-8',
  lg: 'h-10',
  xl: 'h-14',
  '2xl': 'h-16',
};

const iconSizes = {
  xs: '8px',
  sm: '12px',
  md: '16px',
  lg: '20px',
  xl: '24px',
  '2xl': '32px',
};

const iconContainerSizeStyle = computed(() => iconContainerSizeStyles[props.size]);
const iconSize = computed(() => iconSizes[props.size]);

const iconIntensities = {
  primary: 700,
  secondary: 600,
};

const iconColorStyle = computed(() => (
  `fill-${props.color}-${iconIntensities[props.variant]}`
));

const iconContainerVariantStyle = computed(() => (
  {
    primary: `bg-${props.color}-100`,
    secondary: `bg-white border border-${props.color}-200`,
  }[props.variant]
));

const roundedBorderRadius = {
  xs: 'rounded-sm',
  sm: 'rounded',
  md: 'rounded-lg',
  lg: 'rounded-lg',
  xl: 'rounded-lg',
  '2xl': 'rounded-lg',
};

const iconContainerShapeStyle = computed(() => ({
  circle: 'rounded-full',
  rounded: roundedBorderRadius[props.size],
}[props.shape]));

</script>

<template>
  <div
    class="flex aspect-square items-center justify-center"
    :class="[iconContainerSizeStyle, iconContainerVariantStyle, iconContainerShapeStyle]"
  >
    <component
      :is="icon"
      :class="iconColorStyle"
      :size="iconSize"
    />
  </div>
</template>
