<script setup lang="ts">
import { Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption } from '@headlessui/vue';
import { PhWarningCircle, PhCaretDown, PhCaretUp } from '@phosphor-icons/vue';
import { useField } from 'vee-validate';
import { computed, toRef } from 'vue';

import useAttrsWithoutClass from '@/composables/useAttrsWithoutClass';
import useFieldError from '@/composables/useFieldError';
import type { SelectOption } from '@/types/select-option';

type Color = 'primary';

type SelectOptionsById = {
  [key: string]: string;
}

type ColorClasses = {
  [key in Color]: string;
};

export type Props = {
  /** Name for the vee-validate field */
  name: string;
  /** List of options to be displayed on the dropdown. Each option should have an id and a name */
  options: SelectOption[];
  /** Value of the field. Can be bound with v-model */
  modelValue?: string | number | SelectOption;
  /** Determines the key that defines the value of the options. */
  modelKey?: 'id' | 'name';
  /** Label text to be shown above the dropdown */
  label?: string;
  /** Placeholder text to be shown when no option has been selected */
  placeholder?: string;
  /** Helper text to be shown below the dropdown */
  helperText?: string;
  /** Position of the helper text */
  helperPosition?: 'left' | 'right';
  /** Set to true to disable the dropdown */
  disabled?: boolean;
  /** Color for dropdown decorations such as border-color when hovering/selected or icon color */
  color?: Color;
};

const props = withDefaults(
  defineProps<Props>(),
  {
    modelValue: undefined,
    modelKey: undefined,
    label: undefined,
    placeholder: undefined,
    helperText: undefined,
    helperPosition: 'left',
    disabled: false,
    color: 'primary',
  },
);

const attrsWithoutClass = useAttrsWithoutClass();

const name = toRef(props, 'name');

const {
  value: selectedOption,
  errorMessage,
  meta,
  handleBlur,
  handleChange,
} = useField(name, undefined, {
  initialValue: props.modelValue,
  syncVModel: true,
});

const { showError, hideError } = useFieldError({ errorMessage, meta });

function calculateOptionValue(option: SelectOption) {
  return props.modelKey ? option[props.modelKey] : option;
}

const selectOptionsById = computed(() => (props.options
  .reduce((hash, selectOption) => {
    hash[selectOption.id.toString()] = selectOption.name;

    return hash;
  }, {} as SelectOptionsById)
));

const selectedOptionName = computed(() => {
  if (!selectedOption.value) return null;
  if (props.modelKey === 'name') return selectedOption.value as string;
  if (props.modelKey === 'id') return selectOptionsById.value[selectedOption.value.toString()];

  return (selectedOption.value as SelectOption).name;
});

const helperPositionStyles = {
  left: 'self-start',
  right: 'self-end',
};

const helperPosition = computed(() => helperPositionStyles[props.helperPosition]);

const buttonColorStyle = computed(() => `hover:border-${props.color}-700`);
const buttonOpenColorStyle = computed(() => `border-${props.color}-700`);
const selectedColorStyle = computed(() => `text-${props.color}-700`);
const optionColorStyle = computed(() => `hover:text-${props.color}-500`);

/* Force tailwind to include the possible classes in bundle */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const colorClasses : ColorClasses = {
  primary: 'hover:border-primary-700 border-primary-700 text-primary-700 hover:text-primary-500',
};
</script>
<template>
  <div
    :class="$attrs.class"
    class="relative flex w-full flex-col gap-2"
  >
    <label
      v-if="label"
      data-testid="label"
      class="text-xs"
      :for="name"
      :class="{ 'text-gray-400': disabled }"
    >
      {{ label }}
    </label>
    <Listbox
      :id="name"
      v-slot="{ open }"
      v-model="selectedOption"
      class="relative w-full flex-col"
      as="div"
      :disabled="disabled"
      :data-testid="name"
      @update:model-value="handleChange"
      @blur="handleBlur"
    >
      <ListboxButton
        data-testid="button"
        v-bind="attrsWithoutClass"
        class="w-full rounded-lg border px-4 py-3 text-left text-sm leading-4 text-gray-900 placeholder:text-gray-300 disabled:bg-gray-100 disabled:text-gray-300 disabled:hover:border-gray-300"
        :class="[
          buttonColorStyle,
          {
            'border-gray-300': hideError,
            'border-error-600 bg-error-25 pr-12 focus:outline-error-600': showError,
            [buttonOpenColorStyle]: open,
          }
        ]"
      >
        <span>{{ selectedOptionName ?? placeholder }}</span>
        <ph-caret-up
          v-if="open"
          class="absolute right-[17px] top-[13px] z-0 h-4 w-4"
          :class="selectedColorStyle"
        />
        <ph-caret-down
          v-else
          class="absolute right-[17px] top-[13px] z-0 h-4 w-4"
          :class="selectedColorStyle"
        />
      </ListboxButton>
      <ListboxOptions class="absolute top-12 z-10 max-h-[204px] w-full overflow-y-auto rounded-md border border-gray-100 bg-white shadow-sm">
        <ListboxOption
          v-for="(option, optionIndex) in options"
          :key="optionIndex"
          class="w-full border-gray-100 px-4 py-3 text-left text-sm leading-4 text-gray-900"
          :class="[
            optionColorStyle,
            { 'border-b': optionIndex!== options.length -1 },
            { [selectedColorStyle]: selectedOptionName === option.name }
          ]"
          :value="calculateOptionValue(option)"
          :data-testid="option.id"
        >
          {{ option.name }}
        </ListboxOption>
      </ListboxOptions>
    </Listbox>
    <span
      v-if="helperText && !showError"
      data-testid="helper"
      class="text-xs text-gray-400"
      :class="helperPosition"
    >
      {{ helperText }}
    </span>
    <div
      v-else-if="showError"
      data-testid="error-helper"
      class="flex gap-2 text-xs text-error-600"
    >
      <ph-warning-circle class="h-4 w-4" />
      <span>{{ errorMessage }}</span>
    </div>
  </div>
</template>
