<script setup lang="ts">
import { RadioGroup } from '@headlessui/vue';
import { ref, watch } from 'vue';

import type { SectionOptions } from '@/types/sections/section-options';
import type { SectionTypeName } from '@/types/sections/section-type-name';

import SectionFormStyleSelectOption from './section-form-style-select-option.vue';

export type Props = {
  options: SectionOptions;
  modelValue: SectionTypeName;
  sectionBaseType: string;
}

const props = defineProps<Props>();
const emit = defineEmits<{(e: 'update:modelValue', value: SectionTypeName): void}>();

const selectedType = ref(props.modelValue);

watch(() => props.modelValue, () => {
  selectedType.value = props.modelValue;
});

watch(selectedType, () => {
  emit('update:modelValue', selectedType.value);
});

</script>
<template>
  <div class="space-y-3 rounded-lg border border-gray-300 p-5">
    <h3 class="text-base font-semibold text-gray-900">
      {{ $t('sections.sectionType') }}
    </h3>
    <RadioGroup
      v-model="selectedType"
      class="flex flex-col gap-4 lg:grid lg:grid-cols-2"
    >
      <section-form-style-select-option
        v-for="(_option, sectionType) in options"
        :key="sectionType"
        :data-testid="`${sectionType}Option`"
        :section-base-type="sectionBaseType"
        :name="sectionType"
      />
    </RadioGroup>
  </div>
</template>
