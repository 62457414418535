<script setup lang="ts">
import { useMutation } from 'vue-query';

import webAppCouponApi from '@/api/webAppCoupon';
import type { WebAppCoupon } from '@/types/web-app-coupon';

interface Props {
  isOpen: boolean;
  webAppCoupon: WebAppCoupon;
}

const props = defineProps<Props>();

const emit = defineEmits<{(e: 'couponDeleted'): void, (e: 'close'): void }>();

const { mutate: deleteWebAppCoupon, isError, isLoading } = useMutation(
  () => webAppCouponApi.delete(props.webAppCoupon.id),
  {
    onSuccess: () => {
      emit('couponDeleted');
    },
  },
);
</script>

<template>
  <base-modal
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template #content>
      <template
        v-if="!isError"
      >
        <h3 class="mt-4 text-xl font-medium">
          {{ $t('functionalities.coupons.deleteCoupon') }}
        </h3>

        <h4 class="mt-8">
          {{ $t('functionalities.coupons.deleteCouponDescription', { name: webAppCoupon.name }) }}
        </h4>
      </template>
      <base-error
        v-else
        :message="$t('functionalities.coupons.errors.deleteCoupon')"
      />
    </template>
    <template #actions>
      <base-button
        theme="secondary"
        :label="$t('actions.cancel')"
        @click="$emit('close')"
      />
      <base-button
        v-if="!isError"
        theme="primary"
        :label="$t('functionalities.coupons.deleteCouponConfirmation')"
        :loading="isLoading"
        @click="deleteWebAppCoupon"
      />
    </template>
  </base-modal>
</template>
