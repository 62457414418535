<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  status: 'connected' |'disconnected' |'error';
}

const props = defineProps<Props>();

const statusThemes = {
  connected: 'success',
  disconnected: 'warning',
  error: 'error',
};

const message = computed(() => (`webApp.fields.statuses.${props.status}`));
const theme = computed(() => (statusThemes[props.status]));

</script>

<template>
  <base-pill
    :theme="theme"
  >
    <div class="flex items-center gap-1">
      <base-svg
        name="circle"
        class="h-2 w-2"
      />
      <p>{{ $t(message) }}</p>
    </div>
  </base-pill>
</template>
