import { serialize } from 'object-to-formdata';

import type { SalesServiceForm } from '@/types/sales-service-form';
import type { Service } from '@/types/service';

import api from './index';

const BASE_WEB_APP_PATH = '/api/internal/web_apps';
const BASE_SALES_SERVICE_PATH = '/api/internal/sales_services';

export default {
  async index(webAppId: number) {
    const path = `${BASE_WEB_APP_PATH}/${webAppId}/sales_services`;

    const response = await api({
      method: 'get',
      url: path,
    });

    return response.data.services as Service[];
  },
  create(webAppId: number, categoryId: number, salesService: SalesServiceForm) {
    const path = `${BASE_WEB_APP_PATH}/${webAppId}/sales_services`;

    return api({
      method: 'post',
      url: path,
      data: serialize({
        salesService: {
          ...salesService,
          webAppId,
          categoryId,
        },
      }),
    });
  },
  update(salesServiceId: number, salesService: SalesServiceForm) {
    const path = `${BASE_SALES_SERVICE_PATH}/${salesServiceId}`;

    return api({
      method: 'put',
      url: path,
      data: serialize({
        salesService,
      }),
    });
  },
  delete(salesServiceId: number) {
    const path = `${BASE_SALES_SERVICE_PATH}/${salesServiceId}`;

    return api({
      method: 'delete',
      url: path,
    });
  },
};
