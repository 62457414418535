<script setup lang="ts">
import { computed } from 'vue';

const variants = {
  slate: 'bg-slate-100 text-slate-500',
  orange: 'bg-orange-100 text-orange-500',
};

interface Props {
  leftOption: string;
  rightOption: string;
  checked: boolean;
  leftVariant?: keyof typeof variants;
  rightVariant?: keyof typeof variants;
}

const props = withDefaults(defineProps<Props>(), {
  leftVariant: 'slate',
  rightVariant: 'orange',
});

const selectedClass = 'rounded-full font-semibold';

const leftSelectedOptionClass = computed(() => `${selectedClass} ${variants[props.leftVariant]}`);
const rightSelectedOptionClass = computed(() => `${selectedClass} ${variants[props.rightVariant]}`);

</script>
<template>
  <div class="flex h-6 w-fit items-center justify-center rounded-full border border-gray-300 px-px py-1 text-gray-500">
    <div
      class="flex h-5 items-center justify-center px-2.5"
      :class="{ [leftSelectedOptionClass]: !checked }"
    >
      {{ leftOption }}
    </div>
    <div
      class="flex h-5 items-center justify-center px-2.5"
      :class="{ [rightSelectedOptionClass]: checked }"
    >
      {{ rightOption }}
    </div>
  </div>
</template>
