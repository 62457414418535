<script setup lang="ts">
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import StarterKit from '@tiptap/starter-kit';
import { useEditor, EditorContent } from '@tiptap/vue-3';

interface Props {
  text: string;
}

const props = defineProps<Props>();

const editor = useEditor({
  content: props.text,
  editable: false,
  extensions: [
    StarterKit,
    TextStyle,
    Color,
  ],
});
</script>
<template>
  <editor-content
    :editor="editor"
  />
</template>
