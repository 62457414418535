<script setup lang="ts">
import { ref, computed } from 'vue';

import type { CountryService } from '@/types/country-service';
import type { WebAppCountryService } from '@/types/web-app-country-service';

interface Props {
  item: WebAppCountryService | CountryService;
  hasLoyalty: boolean;
  mode?: 'added' | 'available';
}

interface Emits {
  (event: 'show'): void,
  (event: 'edit'): void,
  (event: 'add'): void,
  (event: 'undoAdd'): void,
}

const props = withDefaults(defineProps<Props>(), {
  mode: 'added',
});
const emit = defineEmits<Emits>();

const willBeAdded = ref(false);

function add() {
  willBeAdded.value = true;
  emit('add');
}

function undoAdd() {
  willBeAdded.value = false;
  emit('undoAdd');
}

const withoutLoyalty = computed(() => !props.hasLoyalty);

</script>
<template>
  <div class="grid grid-cols-4 items-center border border-r-0 border-gray-200 bg-white px-8 py-2 md:border-r">
    <p class="col-span-2">
      {{ item.name }}
    </p>
    <div
      v-if="mode === 'added'"
      class="relative col-span-2 grid"
      :class="{'grid-cols-3': hasLoyalty, 'grid-cols-2': withoutLoyalty}"
    >
      <div
        v-if="hasLoyalty"
        class="col-span-1 flex items-center justify-start"
      >
        <div
          v-if="item.pointCost > 0"
          class="w-fit rounded-xl bg-blue-100 px-2 py-0.5 text-center text-xs text-blue-800"
        >
          {{ $t("webAppCountryServices.fields.types.redeemable.label") }}
        </div>
        <div
          v-else-if="(item as WebAppCountryService).accumulationRate.toString() === '0.0'"
          class="w-fit rounded-xl  bg-success-100 px-2 py-0.5 text-center text-xs text-success-800"
        >
          {{ $t("webAppCountryServices.fields.types.included.label") }}
        </div>
        <div
          v-else
          class="w-fit rounded-xl bg-warning-50 px-2 py-0.5 text-center text-xs text-warning-800"
        >
          {{ $t("webAppCountryServices.fields.types.rewardable.label") }}
        </div>
      </div>
      <base-icon-button
        data-testid="show-country-service-button"
        variant="light-gray"
        icon-name="eye"
        :label="$t('actions.show')"
        label-visibility="lg-visible"
        @click="() => emit('show')"
      />
      <base-icon-button
        data-testid="edit-country-service-button"
        variant="light-gray"
        icon-name="gear"
        :label="$t('actions.customize')"
        label-visibility="lg-visible"
        @click="() => emit('edit')"
      />
    </div>
    <div
      v-else-if="mode === 'available'"
      class="col-span-2 flex justify-end"
    >
      <base-button
        v-if="!willBeAdded"
        icon-file-name="add"
        theme="success-link"
        :label="$t('countryServices.actions.addService')"
        @click="add"
      />
      <base-button
        v-else
        icon-file-name="undo-icon"
        theme="error-link"
        :label="$t('actions.undo')"
        @click="undoAdd"
      />
    </div>
  </div>
</template>
