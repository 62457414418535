<script setup lang="ts">
import { useField } from 'vee-validate';

import useAttrsWithoutClass from '@/composables/useAttrsWithoutClass';
import useFieldError from '@/composables/useFieldError';

const labelSizeClass = {
  xs: 'text-xs',
  sm: 'text-sm',
};

const inputSizeClass = {
  xs: 'h-8 placeholder:text-xs',
  sm: 'h-10 placeholder:text-sm',
};

const colorInputSizeClass = {
  xs: 'top-1',
  sm: 'top-2',
};

interface Props {
  modelValue?: string;
  name: string;
  size?: keyof typeof labelSizeClass;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  size: 'sm',
  disabled: false,
});

const attrsWithoutClass = useAttrsWithoutClass();

const emit = defineEmits<{(e: 'update:modelValue', value: string): void}>();

const {
  value: inputValue,
  errorMessage,
  meta,
  handleBlur,
  handleChange,
} = useField(props.name, undefined, {
  initialValue: props.modelValue,
});

const { showError, hideError } = useFieldError({ errorMessage, meta });

function handleInputChange(e: Event) {
  handleChange(e);
  const input = e.target as HTMLInputElement;
  emit('update:modelValue', input.value);
}

</script>
<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<template>
  <div class="flex flex-col">
    <p class="text-2xs font-light text-gray-400">
      HEX
    </p>
    <div
      class="flex w-full items-center space-x-5 rounded-md border bg-white p-1.5 outline-none"
      :class="{ 'border-error-600': showError, ' border-gray-300': hideError }"
    >
      <input
        tabindex="1"
        :value="inputValue"
        type="color"
        class="h-8 w-14 rounded-lg bg-transparent outline-none"
        :class="colorInputSizeClass[size]"
        :disabled="disabled"
        @input="handleInputChange"
        @blur="handleBlur"
      >
      <input
        :id="name"
        :value="inputValue"
        type="text"
        class="w-full border-0 text-xs font-normal uppercase text-gray-700 outline-none"
        :class="[{ 'placeholder:text-error-300': showError }, inputSizeClass[size]]"
        :disabled="disabled"
        v-bind="attrsWithoutClass"
        @input="handleInputChange"
        @blur="handleBlur"
      >
    </div>
    <p
      v-show="showError"
      class="text-left text-xs text-error-600"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
