<script setup lang="ts">
import { computed, ref } from 'vue';

import {
  getSectionFieldLabel, getSectionFieldDescription,
} from '@/utils/get-section-field-translations';

import BaseLogoInput from '../base-logo-input.vue';

type ImageData = {
  imageUrl: string
}

function isImageData(file: File | ImageData): file is ImageData {
  return (file as ImageData).imageUrl !== undefined;
}

export type Props = {
  modelValue?: File | ImageData;
  name: string;
  baseType: string;
  required?: boolean;
  previewUrl?: string;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  previewUrl: undefined,
  required: false,
  disable: false,
});

const emit = defineEmits<{(e: 'update:modelValue', value: File): void; (e: 'delete'): void}>();
const label = getSectionFieldLabel(props.baseType, props.name);
const description = getSectionFieldDescription(props.baseType, props.name);

const previewImageUrl = computed(() => {
  const value = props.modelValue;

  return value && isImageData(value) ? value.imageUrl : props.previewUrl;
});

function handleFileUpdate(file: File) {
  emit('update:modelValue', file);
}

function urlToFile(url: string, filename: string): Promise<File> {
  return fetch(url)
    .then(res => res.blob())
    .then(blob => new File([blob], filename, { type: blob.type }));
}

const fileModelValue = ref<File | undefined>();

if (props.modelValue && isImageData(props.modelValue)) {
  urlToFile(props.modelValue.imageUrl, 'image.png').then(file => {
    fileModelValue.value = file;
  });
}
</script>

<template>
  <div class="space-y-3 rounded-lg border border-gray-300 p-5">
    <p class="text-base font-semibold text-gray-900">
      {{ $t(`sections.${baseType}.sectionFieldsTitles.${name}`) }}
    </p>
    <div class="space-y-3 rounded-lg border border-gray-300 p-5">
      <base-logo-input
        :model-value="fileModelValue"
        :label="label"
        :description="description"
        :name="name"
        :preview-url="previewImageUrl"
        :dark-mode="false"
        :required="props.required"
        :disabled="props.disable"
        :show-delete="true"
        @update:model-value="handleFileUpdate"
        @delete="emit('delete')"
      />
    </div>
  </div>
</template>
