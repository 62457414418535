import { csrfToken } from '@rails/ujs';
import axios, { type AxiosRequestTransformer, type AxiosResponseTransformer } from 'axios';

import convertKeys from '@/utils/case-converter';

const api = axios.create({
  transformRequest: [
    (data: any) => convertKeys(data, 'decamelize'),
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
    (data: any) => convertKeys(data, 'camelize'),
  ],
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-CSRF-Token': csrfToken(),
  },
});

export default api;
