<script setup lang="ts">
import { computed, inject, ref } from 'vue';

import useWebAppCouponsQuery from '@/composables/useWebAppCouponsQuery';
import type { WebApp } from '@/types/web-app';
import type { WebAppCoupon } from '@/types/web-app-coupon';
import searchDebounce from '@/utils/search-debounce';

import WebAppFunctionalitiesCouponsActions from './web-app-functionalities-coupons-actions.vue';
import WebAppFunctionalitiesCouponsDeleteModal from './web-app-functionalities-coupons-delete-modal.vue';
import WebAppFunctionalitiesCouponsEditModal from './web-app-functionalities-coupons-edit-modal.vue';
import WebAppFunctionalitiesCouponsListFiltersModal from './web-app-functionalities-coupons-list-filters-modal.vue';

const webApp = inject<WebApp>('webApp');

const webAppId = computed(() => webApp?.id || 0);

const isFiltersModalOpen = ref(false);

const {
  webAppCoupons,
  filters,
  refetch,
} = useWebAppCouponsQuery(
  webAppId.value,
);

const headers = [
  'name',
  'categories',
  'properties',
  'points',
  'discount',
  'actions',
];

const isEditModalOpen = ref(false);
const isDeleteModalOpen = ref(false);
const selectedWebAppCoupon = ref<WebAppCoupon | null>(null);

function openEditModal(webAppCoupon: WebAppCoupon) {
  selectedWebAppCoupon.value = webAppCoupon;
  isEditModalOpen.value = true;
}

function openDeleteModal(webAppCoupon: WebAppCoupon) {
  selectedWebAppCoupon.value = webAppCoupon;
  isDeleteModalOpen.value = true;
}

function onCouponUpdated() {
  refetch.value();
  isEditModalOpen.value = false;
}

function onCouponDeleted() {
  refetch.value();
  isDeleteModalOpen.value = false;
}

function couponCategoryNames(coupon: WebAppCoupon) {
  return coupon.cuponstarCategories.map((category) => category.name).join(', ');
}

const debounceUpdateSearch = searchDebounce((value: string) => {
  filters.couponNameCont = value;
});

function updateFilters() {
  refetch.value();
  isFiltersModalOpen.value = false;
}
</script>
<template>
  <div class="flex w-full gap-6">
    <base-search
      class="grow"
      :model-value="filters.couponNameCont"
      :placeholder="$t('functionalities.coupons.searchByName')"
      name="search"
      @update:model-value="(value: string) => debounceUpdateSearch(value)"
    />
    <base-button
      theme="secondary"
      icon-file-name="filter"
      :label="$t('functionalities.coupons.filterButton')"
      @click="isFiltersModalOpen = true"
    />
  </div>
  <div class="hidden w-full rounded-lg border border-gray-300 bg-white p-3 md:block">
    <table class="w-full table-fixed text-center text-gray-600">
      <thead>
        <tr class="my-auto h-17">
          <th
            v-for="header in headers"
            :key="header"
            class="font-medium"
          >
            {{ $t(`functionalities.coupons.${header}`) }}
          </th>
        </tr>
      </thead>
      <tbody class="font-normal">
        <tr
          v-for="coupon in webAppCoupons"
          :key="coupon.id"
          class="my-auto h-16 border-t border-gray-100 font-normal"
        >
          <td class="pr-6 text-right">
            {{ coupon.name }}
          </td>
          <td>
            {{ couponCategoryNames(coupon) }}
          </td>
          <td>
            <base-info-switch
              class="mx-auto"
              :left-option="$t('functionalities.coupons.included')"
              :right-option="$t('functionalities.coupons.redeemable')"
              :checked="!coupon.included"
            />
          </td>
          <td>
            {{ coupon.included ? '---' : `${coupon.pointCost} pts` }}
          </td>
          <td>
            {{ coupon.discount }}
          </td>
          <td>
            <div
              class="flex items-center justify-center gap-2"
            >
              <web-app-functionalities-coupons-actions
                mode="show"
                @edit="() => openEditModal(coupon)"
                @delete="() => openDeleteModal(coupon)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div
    v-for="coupon in webAppCoupons"
    :key="coupon.id"
    class="flex w-full flex-col gap-4 rounded-lg border border-gray-300 bg-white p-5 text-xs md:hidden"
  >
    <div class="flex flex-col gap-2">
      <p class="font-medium">
        {{ $t('functionalities.coupons.name') }}
      </p>
      <p>
        {{ coupon.name }}
      </p>
    </div>
    <div class="flex flex-col gap-2">
      <p class="font-medium">
        {{ $t('functionalities.coupons.categories') }}
      </p>
      <p>
        {{ couponCategoryNames(coupon) }}
      </p>
    </div>
    <div class="flex flex-col gap-2">
      <p class="font-medium">
        {{ $t('functionalities.coupons.properties') }}
      </p>
      <base-info-switch
        :left-option="$t('functionalities.coupons.included')"
        :right-option="$t('functionalities.coupons.redeemable')"
        :checked="!coupon.included"
      />
    </div>
    <div class="flex flex-col gap-2">
      <p class="font-medium">
        {{ $t('functionalities.coupons.points') }}
      </p>
      <p>
        {{ coupon.included ? '---' : `${coupon.pointCost} pts` }}
      </p>
    </div>
    <div class="flex flex-col gap-2">
      <p class="font-medium">
        {{ $t('functionalities.coupons.discount') }}
      </p>
      <p>
        {{ coupon.discount }}
      </p>
    </div>
    <div class="flex flex-col gap-2">
      <p class="font-medium">
        {{ $t('functionalities.coupons.actions') }}
      </p>
      <div class="flex items-center justify-start gap-2">
        <web-app-functionalities-coupons-actions
          mode="show"
          @edit="() => openEditModal(coupon)"
          @delete="() => openDeleteModal(coupon)"
        />
      </div>
    </div>
  </div>
  <web-app-functionalities-coupons-edit-modal
    v-if="selectedWebAppCoupon"
    :web-app-coupon="selectedWebAppCoupon"
    :open="isEditModalOpen"
    has-loyalty
    @close="isEditModalOpen = false"
    @save="onCouponUpdated"
  />
  <web-app-functionalities-coupons-delete-modal
    v-if="selectedWebAppCoupon"
    :web-app-coupon="selectedWebAppCoupon"
    :is-open="isDeleteModalOpen"
    @close="isDeleteModalOpen = false"
    @coupon-deleted="onCouponDeleted"
  />
  <web-app-functionalities-coupons-list-filters-modal
    :is-open="isFiltersModalOpen"
    @close="isFiltersModalOpen = false"
    @update-filters="updateFilters"
  />
</template>
