import { camelize } from 'humps';
import { computed, reactive, watch, type ComputedRef } from 'vue';
import { useMutation } from 'vue-query';

import { update } from '@/api/section';
import type { Section } from '@/types/section';
import type { SectionField } from '@/types/section-field';
import type { SectionOptions } from '@/types/sections/section-options';
import type { SectionUpdateFormData } from '@/types/sections/section-update-form-data';
import { camelizeSection } from '@/utils/sections-case-converter';

function useSectionData({ section, oldSection, sectionFieldsKeys }: {
  section: Section;
  oldSection: Section;
  sectionFieldsKeys: ComputedRef<string[]>;
}) {
  const sectionFields = computed(() => section.sectionFields);
  const sectionFieldsValues = computed(() => Object.fromEntries(
    sectionFields.value.map(sectionField => [camelize(sectionField.groupName), sectionField.value]),
  ));
  const sectionData: SectionUpdateFormData = reactive({
    type: camelize(section.type),
    baseType: camelize(section.baseType),
    position: section.position,
    sectionFields: Object.fromEntries(
      sectionFieldsKeys.value.map(key => [key, sectionFieldsValues.value[key]]),
    ),
  });

  watch(sectionFields, () => {
    const newValues = Object.fromEntries(
      sectionFieldsKeys.value.map(key => [key, sectionFieldsValues.value[key]]),
    );
    Object.assign(sectionData.sectionFields, newValues);
  });

  function resetSectionData() {
    Object.assign(section, camelizeSection(oldSection));
    const initialSectionFields = Object.fromEntries(
      sectionFieldsKeys.value.map(key => [key, sectionFieldsValues.value[key]]),
    );
    Object.keys(sectionData.sectionFields).forEach((key) => {
      if (initialSectionFields.hasOwnProperty(key)) {
        sectionData.sectionFields[key] = initialSectionFields[key];
      } else {
        delete sectionData.sectionFields[key];
      }
    });
  }

  return { sectionData, resetSectionData };
}

function findSectionFieldByGroupName({ section, groupName }: {
  section: Section,
  groupName: string }) {
  return section.sectionFields.find((sectionField: SectionField) => camelize(sectionField.groupName) === groupName);
}

function useSectionFieldsToSave(
  { section, sectionData, sectionFieldsKeys } : {
    section: Section,
    sectionData: SectionUpdateFormData,
    sectionFieldsKeys: ComputedRef<string[]>
  }) {
  const sectionFieldsKeysDataEntries : ComputedRef<[string, string | boolean | undefined][]> = computed(() => (
    sectionFieldsKeys.value.map(key => [key, sectionData.sectionFields[key]])
  ));
  const sectionFieldsToSaveEntries = computed(() => (
    sectionFieldsKeysDataEntries.value.filter(
      ([key, value]) => findSectionFieldByGroupName({ section, groupName: key })?.value !== value)
  ));
  const sectionFieldsToSave = computed(() => Object.fromEntries(sectionFieldsToSaveEntries.value));

  return { sectionFieldsToSave };
}

function useSectionDataToSave({
  section,
  sectionData,
  oldSection,
  sectionFieldsKeys,
}:{
  section: Section
  sectionData: SectionUpdateFormData,
  oldSection: Section,
  sectionFieldsKeys: ComputedRef<string[]>,
}) {
  const sectionStyleChanged = computed(() => (oldSection.type !== section.type));
  const positionChanged = computed(() => (oldSection.position !== section.position));
  const { sectionFieldsToSave } = useSectionFieldsToSave({ section, sectionData, sectionFieldsKeys });
  const sectionFieldsChanged = computed(() => (Object.keys(sectionFieldsToSave.value).length > 0));
  const sectionDataToSave : ComputedRef<Partial<SectionUpdateFormData>> = computed(() => ({
    type: sectionStyleChanged.value ? section.type : undefined,
    position: positionChanged.value ? section.position : undefined,
    sectionFields: sectionFieldsChanged.value ? sectionFieldsToSave.value : undefined,
  }));
  const needsSave = computed(() => (sectionStyleChanged.value || positionChanged.value || sectionFieldsChanged.value));

  return { sectionDataToSave, needsSave };
}

function useUpdateSectionMutation({ updatedSection, oldSection, sectionDataToSave }: {
  updatedSection: Section;
  oldSection: Section;
  sectionDataToSave: ComputedRef<Partial<SectionUpdateFormData>>;
}) {
  const {
    mutate: updateSectionMutation,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
    isSuccess: isUpdateSuccess,
  } = useMutation(
    () => update({ sectionId: updatedSection.id, sectionData: sectionDataToSave.value }),
    {
      onSuccess: (response) => {
        Object.assign(oldSection, updatedSection);
        Object.assign(updatedSection, camelizeSection(response.section));
      },
    },
  );

  return { updateSectionMutation, isUpdateLoading, isUpdateError, isUpdateSuccess };
}

export function useSectionUpdate(props: {
  options: SectionOptions;
  section: Section;
}) {
  const oldSection : Section = reactive(camelizeSection(props.section));
  const updatedSection : Section = reactive({ ...oldSection });
  const sectionFieldsKeys = computed(() => Object.keys(props.options[updatedSection.type] || {}));
  const { sectionData, resetSectionData } = useSectionData(
    { section: updatedSection, oldSection, sectionFieldsKeys },
  );
  const { sectionDataToSave, needsSave } = useSectionDataToSave(
    { section: updatedSection, sectionData, oldSection, sectionFieldsKeys },
  );
  const { updateSectionMutation, isUpdateLoading, isUpdateError, isUpdateSuccess } = useUpdateSectionMutation(
    { updatedSection, oldSection, sectionDataToSave },
  );

  return {
    updatedSection,
    updatedSectionFields: sectionData.sectionFields,
    updateSection: updateSectionMutation,
    isUpdateLoading,
    isUpdateError,
    isUpdateSuccess,
    needsSave,
    resetForm: resetSectionData,
  };
}
