<script setup lang="ts">
import { Switch } from '@headlessui/vue';
import { ref, watch, computed } from 'vue';

interface Props {
  modelValue?: boolean;
  disabled?: boolean;
  name: string;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  disabled: false,
  label: undefined,
});

const checked = ref(props.modelValue);
const unchecked = computed(() => !checked.value);
const enabled = computed(() => !props.disabled);

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void}>();

watch(() => props.modelValue, (newValue: boolean) => {
  checked.value = newValue;
});
watch(checked, (newValue: boolean) => {
  if (!props.disabled) emit('update:modelValue', newValue);
});

</script>
<template>
  <div class="flex items-center gap-4">
    <Switch
      v-model="checked"
      data-testid="base-switch-switch"
      :class="{ 'bg-primary-700': checked, 'bg-gray-200': unchecked, 'cursor-not-allowed': disabled }"
      class="relative inline-flex h-5 w-8 items-center rounded-full disabled:bg-gray-200"
      :disabled="disabled"
      :name="name"
    >
      <span
        v-if="checked"
        data-testid="base-switch-check"
        class="inline-block h-4 w-4 translate-x-3.5 rounded-full bg-white transition"
        style="box-shadow: 0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A;"
      />
      <span
        v-else
        data-testid="base-switch-uncheck"
        class="inline-block h-4 w-4 translate-x-0.5 rounded-full transition"
        :class="{ 'bg-gray-100': disabled, 'bg-white': enabled }"
        :disabled="disabled"
        style="box-shadow: 0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A;"
      />
    </Switch>
  </div>
</template>
