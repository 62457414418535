<script setup lang="ts">
import { ref, computed, provide } from 'vue';
import { useQuery } from 'vue-query';

import webAppCategoriesApi from '@/api/webAppCategory';
import useWebAppCountryServicesQuery from '@/composables/useWebAppCountryServicesQuery';
import useWebAppSalesServicesQuery from '@/composables/useWebAppSalesServicesQuery';
import type { Category } from '@/types/category';
import type { CountryService } from '@/types/country-service';
import type { Service } from '@/types/service';
import type { WebApp } from '@/types/web-app';
import type { WebAppCategory } from '@/types/web-app-category';
import type { WebAppCountryService } from '@/types/web-app-country-service';
import type { WebAppTabs } from '@/types/web-app-tabs';

import CountryServicesEdit from './country-services-edit.vue';
import CountryServicesSection from './country-services-section.vue';

interface Props {
  webApp: WebApp;
  webAppCountryServices: WebAppCountryService[];
  webAppSalesServices: Service[];
  permittedCountryServices: CountryService[];
  families: Category[];
  categories: Category[];
  webAppCategories: WebAppCategory[];
  backPath: string;
  tabs: WebAppTabs;
}

const props = defineProps<Props>();
const isSalesApp = props.webApp.appType === 'sales';

provide('countryServices', props.permittedCountryServices);

function useWebAppCategoriesQuery() {
  return useQuery(['web-app-categories', props.webApp.id],
    () => (webAppCategoriesApi.index(props.webApp.id)),
    { initialData: props.webAppCategories,
      enabled: false, retry: false },
  );
}

const {
  data: webAppCountryServicesData,
  refetch: refetchWebAppCountryServices,
} = useWebAppCountryServicesQuery(props.webApp.id, props.webAppCountryServices);

const {
  data: webAppSalesServicesData,
  refetch: refetchWebAppSalesServices,
} = useWebAppSalesServicesQuery(props.webApp.id, props.webAppSalesServices);

const {
  data: webAppCategoriesData,
  refetch: refetchWebAppCategories,
} = useWebAppCategoriesQuery();

const updatedWebAppCountryServices = computed(() => (webAppCountryServicesData.value || []));
const updatedWebAppSalesServices = computed(() => (webAppSalesServicesData.value || []));

const updatedCountryServices = computed(() => {
  const countryServices = (updatedWebAppCountryServices.value).map(
    (webAppService: WebAppCountryService) => (webAppService.countryService),
  );

  return countryServices;
});

const updatedWebAppCategories = computed(() => (webAppCategoriesData.value || props.webAppCategories));

const edit = ref(false);

function setEdit(editValue: boolean) {
  edit.value = editValue;
}

function cancelEdit() {
  setEdit(false);
}

function onCountryServicesUpdate() {
  refetchWebAppCountryServices.value();
  setEdit(false);
}

function onSalesServicesUpdate() {
  refetchWebAppSalesServices.value();
}

function onCategoryUpdate() {
  refetchWebAppCategories.value();
}

</script>

<template>
  <the-web-app-layout
    :web-app="webApp"
    selected-tab="countryServices"
    :tabs="tabs"
    :back-path="backPath"
  >
    <section v-if="edit">
      <country-services-edit
        :web-app="webApp"
        :country-services="updatedCountryServices"
        :web-app-country-services="updatedWebAppCountryServices"
        :permitted-country-services="permittedCountryServices"
        @update="onCountryServicesUpdate"
        @cancel="cancelEdit"
      />
    </section>
    <section v-else>
      <country-services-section
        :web-app-id="webApp.id"
        :is-sales-app="isSalesApp"
        :web-app-country-services="updatedWebAppCountryServices"
        :web-app-sales-services="updatedWebAppSalesServices"
        :families="families"
        :categories="categories"
        :web-app-categories="updatedWebAppCategories"
        :has-loyalty="webApp.hasLoyalty"
        @edit="() => setEdit(true)"
        @update="onCountryServicesUpdate"
        @update-category="onCategoryUpdate"
        @update-sales-services="onSalesServicesUpdate"
      />
    </section>
  </the-web-app-layout>
</template>
