<script setup lang="ts">
import Slider from '@vueform/slider';
import { useField } from 'vee-validate';
import { toRef } from 'vue';

const DEFAULT_CONNECT_CLASSES = `absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full
  cursor-pointer tap:duration-300 tap:transition-transform disabled:bg-gray-400 disabled:cursor-not-allowed`;

const DEFAULT_HANDLE_CLASSES = `absolute rounded-full bg-white shadow-slider cursor-grab focus:outline-none
  h:w-4 h:h-4 h:-top-1.5 h:-right-2 txt-rtl-h:-left-2 txt-rtl-h:right-auto v:w-4 v:h-4 v:-top-2 v:-right-1.25
  disabled:cursor-not-allowed focus:ring focus:ring-opacity-30`;

const DEFAULT_TOOLTIP_CLASSES = `absolute block text-sm font-semibold whitespace-nowrap py-1 px-1.5 min-w-5 text-center
  text-white rounded border transform h:-translate-x-1/2 h:left-1/2 v:-translate-y-1/2 v:top-1/2 disabled:bg-gray-400
  disabled:border-gray-400 merge-h:translate-x-1/2 merge-h:left-auto merge-v:-translate-x-4 merge-v:top-auto
  tt-focus:hidden tt-focused:block tt-drag:hidden tt-dragging:block`;

interface Props {
  modelValue?: number[];
  name: string,
  disabled?: boolean;
  min?: number;
  max?: number;
  step?: number;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  disabled: false,
  min: 0,
  max: 100,
  step: 5,
});

const name = toRef(props, 'name');

const {
  value: inputValue,
  errorMessage,
  handleChange,
} = useField(name, undefined, {
  initialValue: props.modelValue,
});

const classes = {
  connect: `${DEFAULT_CONNECT_CLASSES} bg-primary-600`,
  handle: `${DEFAULT_HANDLE_CLASSES} border border-primary-600 focus:ring-primary-600`,
  tooltip: `${DEFAULT_TOOLTIP_CLASSES} bg-primary-600 border-primary-600`,
};
</script>
<template>
  <div
    class="mb-10 mt-8"
    :class="$attrs.class"
  >
    <Slider
      :model-value="inputValue"
      :min="min"
      :max="max"
      :step="step"
      tooltip-position="bottom"
      :classes="classes"
      lazy
      @change="handleChange"
    />
    <p
      v-if="errorMessage"
      class="text-right text-xs text-red-600"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
<style lang="scss">
  @import '@vueform/slider/themes/tailwind.scss'
</style>
