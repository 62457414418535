<script setup lang="ts">
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogPanel,
} from '@headlessui/vue';
import { ref } from 'vue';

export type Props = {
  isOpen: boolean;
}

defineProps<Props>();
defineEmits<{(e: 'close'): void }>();
const initialFocusRef = ref<HTMLElement>();

function setInitialFocusRef(element: HTMLElement) {
  initialFocusRef.value = element;

  return initialFocusRef;
}

</script>

<template>
  <TransitionRoot
    appear
    :show="isOpen"
    as="template"
  >
    <Dialog
      as="div"
      class="relative z-10"
      :initial-focus="initialFocusRef"
      @close="$emit('close')"
    >
      <DialogOverlay
        class="fixed inset-0 bg-black opacity-60"
      />
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-900 opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 flex h-screen items-center justify-center p-4 text-center">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="flex max-h-full max-w-max flex-col overflow-y-auto rounded-2xl bg-white text-left align-middle shadow-xl transition-all md:max-w-xl"
            v-bind="$attrs"
          >
            <slot
              :set-initial-focus-ref="setInitialFocusRef"
            />
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
