import type { WebAppCoupon } from '@/types/web-app-coupon';
import type { WebAppCouponForm } from '@/types/web-app-coupon-form';

import api from './index';

const BASE_WEB_APP_PATH = '/api/internal/web_apps';
const BASE_PATH = '/api/internal/web_app_coupons';

export default {
  async index(webAppId: number, queryParams?: string): Promise<WebAppCoupon[]> {
    let path = `${BASE_WEB_APP_PATH}/${webAppId}/web_app_coupons`;
    path += queryParams ? `?${queryParams}` : '';

    const response = await api({
      method: 'get',
      url: path,
    });

    return response.data.webAppCoupons as WebAppCoupon[];
  },
  bulkCreate(webAppId: number, couponIds: number[]) {
    const path = `${BASE_WEB_APP_PATH}/${webAppId}/web_app_coupons`;

    return api({
      method: 'post',
      url: path,
      data: { couponIds },
    });
  },
  update(webAppCouponId: number, webAppCoupon: WebAppCouponForm) {
    const path = `${BASE_PATH}/${webAppCouponId}`;

    return api({
      method: 'patch',
      url: path,
      data: { webAppCoupon },
    });
  },
  delete(webAppCouponId: number) {
    const path = `${BASE_PATH}/${webAppCouponId}`;

    return api({
      method: 'delete',
      url: path,
    });
  },
};
