<script setup lang="ts">
import { ref, computed } from 'vue';
import { useMutation } from 'vue-query';

import webAppApi from '@/api/webApp';
import type { CountryService } from '@/types/country-service';
import type { WebApp } from '@/types/web-app';
import type { WebAppCountryService } from '@/types/web-app-country-service';
import type { WebAppCountryServicesForm } from '@/types/web-app-country-services-form';

import CountryServicesSelect from './country-services-select.vue';

interface Props {
  webApp: WebApp;
  countryServices: CountryService[];
  webAppCountryServices: WebAppCountryService[],
  permittedCountryServices: CountryService[],
}
const props = withDefaults(
  defineProps<Props>(),
  {},
);

const emit = defineEmits<{(e: 'update'): void, (e: 'cancel'): void}>();

const countryServicesIds = ref(props.countryServices.map((countryService) => countryService.id));

const selectedCountryServicesIds = ref(props.countryServices.map((countryService) => countryService.id));

function checkDeletion(webAppCountryService: WebAppCountryService) {
  return !selectedCountryServicesIds.value.includes(webAppCountryService.countryService.id);
}

function checkAddition(countryServiceId: number) {
  return !countryServicesIds.value.includes(countryServiceId);
}

const deletedWebAppCountryServices = computed(() => {
  const deleted = props.webAppCountryServices.filter((webAppCountryService) => checkDeletion(webAppCountryService));

  return deleted;
});

const newCountryServicesIds = computed(() => (
  selectedCountryServicesIds.value.filter((countryServiceId) => checkAddition(countryServiceId))
));

const {
  mutate,
  isLoading,
  isError,
} = useMutation(
  (values: WebAppCountryServicesForm) => webAppApi.updateCountryServices(values),
  { onSuccess: () => { emit('update'); } },
);

function updateWebAppCountryServices() {
  const webAppCountryServicesForm: WebAppCountryServicesForm = {
    webApp: { id: props.webApp.id },
    newCountryServicesIds: newCountryServicesIds.value,
    deletedWebAppCountryServices: deletedWebAppCountryServices.value,
  };
  mutate(webAppCountryServicesForm);
}

</script>
<template>
  <div class="flex grow justify-end gap-4 text-sm md:text-base">
    <base-button
      theme="secondary"
      :label="$t('actions.cancel')"
      @click="() => $emit('cancel')"
    />
    <base-button
      theme="primary"
      :loading="isLoading"
      :label="$t('actions.saveChanges')"
      @click="updateWebAppCountryServices"
    />
  </div>
  <base-error
    v-if="isError"
    :message="$t('services.errors.update')"
  />
  <div class="my-6 flex flex-col space-y-4 rounded-lg border border-gray-300 bg-white p-6">
    <country-services-select
      v-model="selectedCountryServicesIds"
      :country-services="countryServices"
      :permitted-country-services="permittedCountryServices"
    />
  </div>
</template>
