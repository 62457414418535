import { serialize } from 'object-to-formdata';

import { BenefitGroup } from '@/types/benefit-group';
import { BenefitGroupForm } from '@/types/benefit-group-form';

import api from './index';

const BASE_WEB_APP_PATH = '/api/internal/web_apps';
const BASE_PATH = '/api/internal/benefit_groups';

export default {
  async index(webAppId: number): Promise<BenefitGroup[]> {
    const path = `${BASE_WEB_APP_PATH}/${webAppId}/benefit_groups`;

    const response = await api({
      method: 'get',
      url: path,
    });

    return response.data.benefitGroups as BenefitGroup[];
  },
  create(webAppId: number, benefitGroup: BenefitGroupForm) {
    const path = `${BASE_WEB_APP_PATH}/${webAppId}/benefit_groups`;

    return api({
      method: 'post',
      url: path,
      data: serialize({ benefitGroup }),
    });
  },
  update(benefitGroupId: number, benefitGroup: BenefitGroupForm) {
    const path = `${BASE_PATH}/${benefitGroupId}`;

    return api({
      method: 'patch',
      url: path,
      data: serialize({ benefitGroup }),
    });
  },
  delete(benefitGroupId: number) {
    const path = `${BASE_PATH}/${benefitGroupId}`;

    return api({
      method: 'delete',
      url: path,
    });
  },
};
