<script setup lang="ts">
import { computed, inject } from 'vue';

import type { DesignConfig } from '@/types/design-config';
import { getSectionFieldLabel } from '@/utils/get-section-field-translations';

export type Props = {
  name: string;
  baseType: string;
  modelValue?: string;
}

const props = defineProps<Props>();
const emit = defineEmits<{(e: 'update:modelValue', value: string | undefined): void}>();
const label = getSectionFieldLabel(props.baseType, props.name);
const designConfig: DesignConfig | undefined = inject('designConfig');

const colors = computed(() => [
  designConfig?.primaryColor,
  designConfig?.secondaryColor,
].filter(Boolean));

function handleChange(value: string | undefined) {
  emit('update:modelValue', value);
}

</script>

<template>
  <div class="space-y-3 rounded-lg border border-gray-300 p-5">
    <p class="text-base font-semibold text-gray-900">
      {{ label }}
    </p>
    <base-rich-input
      :model-value="modelValue"
      :name="name"
      :colors="colors"
      @update:model-value="handleChange"
    />
  </div>
</template>
