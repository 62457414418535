
import { kebabCase } from 'lodash';
import { type ComputedRef, ref, watch, computed } from 'vue';

const imageImports = import.meta.glob<string>('../../assets/images/sections-previews/*.jpg', { import: 'default' });

export function useSectionPreviewImageSrc(sectionType: ComputedRef<string>) {
  const imagePath = computed(() => (
    `../../assets/images/sections-previews/sections-${kebabCase(sectionType.value)}-preview.jpg`
  ));

  const sectionPreviewImageSrc = ref<string>();
  function updateSectionPreviewImageSrc() {
    imageImports[imagePath.value]().then((imageImport) => {
      sectionPreviewImageSrc.value = imageImport;
    });
  }

  updateSectionPreviewImageSrc();

  watch(sectionType, () => {
    updateSectionPreviewImageSrc();
  });

  return { sectionPreviewImageSrc };
}
