import { serialize } from 'object-to-formdata';

import type { WebAppCategory } from '@/types/web-app-category';

import api from './index';

const BASE_WEB_APP_PATH = '/api/internal/web_apps';

export default {
  async index(webAppId: number): Promise<WebAppCategory[]> {
    const path = `${BASE_WEB_APP_PATH}/${webAppId}/web_app_categories`;

    const response = await api({
      method: 'get',
      url: path,
    });

    return response.data.webAppCategories as WebAppCategory[];
  },
  update(webAppId: number, categoryId: number, webAppCategory: WebAppCategory) {
    const path = `${BASE_WEB_APP_PATH}/${webAppId}/web_app_categories/${categoryId}`;

    return api({
      method: 'put',
      url: path,
      data: serialize({ webAppCategory }),
    });
  },
};
