<script setup lang="ts">
import { computed, ref } from 'vue';

import useVisibilityTimeout from '@/composables/useVisibilityTimeout';
import type { FunctionalityTab, FunctionalityTabName } from '@/types/functionality';
import type { WebApp } from '@/types/web-app';
import type { WebAppTabs } from '@/types/web-app-tabs';

import BaseBadge from './base-badge.vue';
import ThePageHeader from './the-page-header.vue';

export type Props = {
  webApp: WebApp;
  backPath: string,
  webAppTabs: WebAppTabs;
  selectedTab: FunctionalityTabName;
  isUpdateSuccess: boolean;
  isUpdateError: boolean;
}

const props = defineProps<Props>();
const functionalityTabs: {name: FunctionalityTabName, href: string}[] = [
  {
    name: 'general',
    href: `/web_apps/${props.webApp.id}/functionalities`,
  },
  {
    name: 'coupons',
    href: `/web_apps/${props.webApp.id}/functionalities/coupons`,
  },
  {
    name: 'legalDocuments',
    href: `/web_apps/${props.webApp.id}/functionalities/legal_documents`,
  },
];
const selectedTab = ref(props.selectedTab);
const tabs = ref<FunctionalityTab[]>(functionalityTabs);

const isUpdateSuccess = computed(() => props.isUpdateSuccess);
const isUpdateError = computed(() => props.isUpdateError);

const VISIBILITY_TIMEOUT = 3000;

const { isVisible: isSuccessMessageVisible } = useVisibilityTimeout(
  { condition: isUpdateSuccess, timeout: VISIBILITY_TIMEOUT, reload: true },
);
const { isVisible: isErrorMessageVisible } = useVisibilityTimeout(
  { condition: isUpdateError, timeout: VISIBILITY_TIMEOUT, reload: true },
);

function selectTab(tab: FunctionalityTab) {
  window.location.href = tab.href;
}

function isSelectedTab(tab: FunctionalityTab) {
  return selectedTab.value === tab.name;
}

</script>

<template>
  <the-web-app-layout
    :web-app="webApp"
    selected-tab="functionalities"
    :tabs="webAppTabs"
    :back-path="backPath"
  >
    <the-page-header
      :title="$t('functionalities.title')"
      :description="$t('functionalities.description')"
      emoji="⭐️"
    >
      <template #notice>
        <base-badge
          v-if="isErrorMessageVisible"
          :label="$t('common.saveError')"
          color="error"
          theme="dark"
        />
        <base-badge
          v-else-if="isSuccessMessageVisible"
          :label="$t('common.savedSuccessfully')"
          color="success"
          theme="dark"
        />
      </template>
    </the-page-header>
    <div class="mb-2">
      <div class="flex">
        <base-tab
          v-for="tab in tabs"
          :key="tab.name"
          :data-testid="`${tab.name}-tab`"
          class="-mb-px"
          :selected="isSelectedTab(tab)"
          :label="$t(`functionalities.tabs.${tab.name}`)"
          @select="() => selectTab(tab)"
        />
      </div>
      <hr class="-mx-4 border-gray-200 md:-mx-12">
    </div>
    <slot />
  </the-web-app-layout>
</template>
