<script setup lang="ts">
import { camelize } from 'humps';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { WebAppService } from '@/types/web-app-service';

import BaseRadioInputGroup from './base-radio-input-group.vue';

type LoyaltyType = 'included' | 'redeemable' | 'rewardable';
export interface Props {
  loyaltyType?: LoyaltyType;
  redemptionKind?: WebAppService['redemptionKind'];
  accumulationRate?: number | string;
  pointCost?: number;
}
const props = withDefaults(defineProps<Props>(), {
  loyaltyType: undefined,
  redemptionKind: 'single_use',
  accumulationRate: undefined,
  pointCost: undefined,
});

interface Emits {
  (event: 'update:loyalty-type', value: LoyaltyType): void;
  (event: 'update:redemption-kind', value: WebAppService['redemptionKind']): void;
  (event: 'update:accumulation-rate', value: number): void;
  (event: 'update:point-cost', value: number): void;
}
const emit = defineEmits<Emits>();

const { t } = useI18n({});

const isRedeemable = computed(() => props.loyaltyType === 'redeemable');
const isRewardable = computed(() => props.loyaltyType === 'rewardable');

const camelizedRedemptionKind = computed(() => camelize(props.redemptionKind));
const loyaltyTypeOptions = computed(() => (
  ['included', 'redeemable', 'rewardable'].map((loyaltyTypeOption) => ({
    value: loyaltyTypeOption, label: t(`webAppServices.form.fields.loyaltyTypes.labels.${loyaltyTypeOption}`),
  }))
));
const redemptionKindOptions = computed(() => (
  ['single_use', 'unlimited_access'].map((redemptionKind) => ({
    value: redemptionKind, label: t(`webAppServices.form.fields.redemptionKinds.labels.${camelize(redemptionKind)}`),
  }))
));
</script>

<template>
  <div>
    <base-radio-input-group
      :model-value="loyaltyType"
      data-testid="loyalty-type-input"
      class="mb-3"
      :options="loyaltyTypeOptions"
      name="loyaltyType"
      :label="$t('webAppServices.form.fields.loyaltyType')"
      @update:model-value="emit('update:loyalty-type', $event as LoyaltyType)"
    />
    <div class="rounded bg-gray-50 px-4 py-2 text-xs">
      {{ $t(`webAppServices.form.fields.loyaltyTypes.explanations.${loyaltyType}`) }}
    </div>
  </div>
  <div v-if="isRedeemable">
    <base-radio-input-group
      :model-value="redemptionKind"
      data-testid="redemption-kind-input"
      class="mb-3"
      :options="redemptionKindOptions"
      name="redemptionKind"
      :label="$t('webAppServices.form.fields.redemptionKind')"
      @update:model-value="emit('update:redemption-kind', $event as WebAppService['redemptionKind'])"
    />
    <div class="rounded bg-gray-50 px-4 py-2 text-xs">
      {{ $t(`webAppServices.form.fields.redemptionKinds.explanations.${camelizedRedemptionKind}`) }}
    </div>
  </div>
  <base-input
    v-if="isRewardable"
    :model-value="accumulationRate"
    data-testid="accumulation-rate-input"
    type="number"
    name="accumulationRate"
    :label="$t('webAppServices.form.fields.accumulationRate')"
    theme="numeric-input"
    @update:model-value="emit('update:accumulation-rate', Number($event))"
  />
  <base-input
    v-else-if="isRedeemable"
    :model-value="pointCost"
    data-testid="point-cost-input"
    type="number"
    name="pointCost"
    :label="$t('webAppServices.form.fields.pointCost')"
    theme="numeric-input"
    @update:model-value="emit('update:point-cost', Number($event))"
  />
</template>
