<script setup lang="ts">
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
} from '@headlessui/vue';
import { ref } from 'vue';

interface Props {
  isOpen: boolean;
}

defineProps<Props>();

const emit = defineEmits<{(e: 'close'): void}>();

function closeModal() {
  emit('close');
}

const initialFocusRef = ref<HTMLElement>();

function setInitialFocusRef(element: HTMLElement) {
  initialFocusRef.value = element;
}
</script>

<template>
  <TransitionRoot
    appear
    :show="isOpen"
    as="template"
  >
    <Dialog
      as="div"
      class="relative z-10"
      :initial-focus="initialFocusRef"
      @close="closeModal"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-900 opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 flex h-screen items-center justify-center p-4 text-center">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="flex max-h-full w-full max-w-md flex-col overflow-y-auto rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all md:max-w-3xl"
          >
            <div class="flex items-start justify-end">
              <slot
                name="title"
              />
              <base-button
                type="button"
                theme="light-link"
                icon-file-name="close"
                @click="closeModal"
              />
            </div>
            <slot
              name="content"
            />
            <div
              v-if="$slots.actions"
              data-testid="base-modal-actions"
              class="mt-8 flex justify-end space-x-6"
            >
              <slot
                name="actions"
                :set-initial-focus-ref="setInitialFocusRef"
              />
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
