import { camelize } from 'humps';
import { type Component } from 'vue';

import SectionsBooleanSectionField from '@/components/sections/boolean-section-field.vue';
import SectionsColorSectionField from '@/components/sections/color-section-field.vue';
import SectionsExtendedColorSectionField from '@/components/sections/extended-color-section-field.vue';
import SectionsImageSectionField from '@/components/sections/image-section-field.vue';
import SectionsLogoSectionField from '@/components/sections/logo-section-field.vue';
import SectionsRichTextSectionField from '@/components/sections/rich-text-section-field.vue';
import SectionsTextSectionField from '@/components/sections/text-section-field.vue';
import type { SectionFieldSchema } from '@/types/sections/section-field-schema';

type ComponentName = 'imageSectionField' | 'textSectionField' |
'richTextSectionField' | 'colorSectionField' |
'booleanSectionField' | 'extendedColorSectionField' |
'logoSectionField';

type ComponentMap = {
  [key in ComponentName]: Component;
};

function getSectionFieldComponent(sectionFieldSchema: SectionFieldSchema) {
  const components : ComponentMap = {
    imageSectionField: SectionsImageSectionField,
    textSectionField: SectionsTextSectionField,
    richTextSectionField: SectionsRichTextSectionField,
    colorSectionField: SectionsColorSectionField,
    booleanSectionField: SectionsBooleanSectionField,
    extendedColorSectionField: SectionsExtendedColorSectionField,
    logoSectionField: SectionsLogoSectionField,
  };

  const sectionFieldType : keyof typeof components = camelize(sectionFieldSchema.type);

  return components[sectionFieldType];
}

export default getSectionFieldComponent;
