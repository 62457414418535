import { useQuery } from 'vue-query';

import webAppSalesServicesApi from '@/api/webAppSalesService';
import type { Service } from '@/types/service';

export default function useWebAppCountryServicesQuery(
  webAppId: number,
  webAppSalesServices?: Service[],
) {
  return useQuery(['web-app-sales-services', webAppId],
    () => (webAppSalesServicesApi.index(webAppId)),
    { initialData: webAppSalesServices,
      enabled: false, retry: false },
  );
}
