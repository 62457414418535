<script setup lang="ts">
import { RadioGroup, RadioGroupOption } from '@headlessui/vue';

import type { ColorOption } from '@/types/color-option';

export type Props = {
  name: string;
  label: string;
  modelValue?: ColorOption;
  colors: Partial<Record<ColorOption, string>>;
}

const props = defineProps<Props>();
const emit = defineEmits<{(e: 'update:modelValue', value: ColorOption | undefined): void;
}>();

function handleChange(value: ColorOption) {
  emit('update:modelValue', value);
}

</script>

<template>
  <div class="space-y-3 rounded-lg border border-gray-300 p-5">
    <p class="text-base font-semibold text-gray-900">
      {{ label }}
    </p>
    <RadioGroup
      :model-value="props.modelValue"
      class="flex flex-col gap-y-2"
      @update:model-value="handleChange"
    >
      <RadioGroupOption
        v-for="[colorOption, colorValue] in Object.entries(props.colors)"
        :key="colorOption"
        :value="colorOption"
        class="grid grid-cols-3 items-center gap-4"
      >
        <div class="col-span-1 flex items-center gap-2">
          <div
            class="flex aspect-square h-4 w-4 items-center justify-center rounded-full"
            :class="{
              'bg-primary-700 text-primary-700': props.modelValue === colorOption,
              'border border-gray-300 text-gray-500': props.modelValue !== colorOption
            }"
          >
            <base-svg
              v-if="props.modelValue === colorOption"
              data-testid="checked-icon"
              class="h-3 rounded-full stroke-white stroke-2"
              name="circle"
            />
          </div>
          <span
            :data-testid="`color-option-${colorOption}`"
            :class="{
              'font-medium text-primary-700': props.modelValue === colorOption,
              'text-gray-500': props.modelValue !== colorOption
            }"
          >
            {{ $t(`designConfig.fields.${colorOption}Color`) }}
          </span>
        </div>
        <div class="col-span-2">
          <base-color-input
            :name="`${props.name}.${colorOption}Color`"
            :model-value="colorValue"
            disabled
            size="xs"
          />
        </div>
      </RadioGroupOption>
    </RadioGroup>
  </div>
</template>
