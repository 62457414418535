
import { computed, ComputedRef } from 'vue';

import { WebAppService } from '@/types/web-app-service';
import { WebAppServiceOption } from '@/types/web-app-service-option';

export default function useInitialLoyaltyValues(
  baseService: ComputedRef<WebAppService | WebAppServiceOption | undefined>,
) {
  const hasNonZeroPointCost = computed(() =>
    baseService.value && 'pointCost' in baseService.value && baseService.value.pointCost !== 0,
  );
  const hasNonZeroAccumulationRate = computed(() =>
    baseService.value && 'accumulationRate' in baseService.value && baseService.value.accumulationRate !== 0,
  );
  const initialLoyaltyType = computed(() => {
    if (hasNonZeroPointCost.value) {
      return 'redeemable';
    } else if (hasNonZeroAccumulationRate.value) {
      return 'rewardable';
    }

    return 'included';
  });
  const isNotInitiallyRedeemableOrHasMoksysKind = computed(() =>
    !baseService.value ||
      initialLoyaltyType.value !== 'redeemable' ||
      (baseService.value.kind && ['scheduled', 'immediate'].includes(baseService.value.kind)),
  );
  const initialRedemptionKind = computed(() => {
    if (baseService.value && 'redemptionKind' in baseService.value && baseService.value.redemptionKind) {
      return baseService.value.redemptionKind;
    } else if (isNotInitiallyRedeemableOrHasMoksysKind.value) {
      return 'single_use';
    }

    return 'unlimited_access';
  });

  return { initialLoyaltyType, initialRedemptionKind };
}
