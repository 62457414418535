import { serialize } from 'object-to-formdata';

import { WebAppService, WebAppServiceForm } from '@/types/web-app-service';

import api from './index';

const BASE_WEB_APP_PATH = '/api/internal/web_apps';
const BASE_PATH = '/api/internal/web_app_services';

export default {
  async index(webAppId: number): Promise<WebAppService[]> {
    const path = `${BASE_WEB_APP_PATH}/${webAppId}/web_app_services`;

    const response = await api({
      method: 'get',
      url: path,
    });

    return response.data.webAppServices as WebAppService[];
  },
  create(webAppId: number, webAppService: WebAppServiceForm) {
    const path = `${BASE_WEB_APP_PATH}/${webAppId}/web_app_services`;

    return api({
      method: 'post',
      url: path,
      data: serialize({ webAppService }),
    });
  },
  update(webAppServiceId: number, webAppService: WebAppServiceForm) {
    const path = `${BASE_PATH}/${webAppServiceId}`;

    return api({
      method: 'patch',
      url: path,
      data: serialize({ webAppService }),
    });
  },
  delete(webAppServiceId: number) {
    const path = `${BASE_PATH}/${webAppServiceId}`;

    return api({
      method: 'delete',
      url: path,
    });
  },
};
