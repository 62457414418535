import type { AxiosResponse } from 'axios';
import { decamelize } from 'humps';
import { serialize } from 'object-to-formdata';

import type { Section } from '@/types/section';
import type { SectionBaseTypeName } from '@/types/section-base-type-name';
import type { SectionUpdateFormData } from '@/types/sections/section-update-form-data';
import { camelizeSection } from '@/utils/sections-case-converter';

import api from './index';

const BASE_PATH = '/api/internal/sections';

interface SectionCreateProps {
  baseType: SectionBaseTypeName;
  webAppId: number;
}

function transformSectionResponse(response: AxiosResponse) {
  if (response.data?.section) return { section: camelizeSection(response.data.section) };

  return response.data;
}

export async function update(
  { sectionId, sectionData }:
  { sectionId: number, sectionData: Partial<SectionUpdateFormData> },
): Promise<{ section: Section }> {
  const data = serialize({
    section: { ...sectionData },
  });
  const path = `${BASE_PATH}/${sectionId}`;

  const response = await api({
    method: 'patch',
    url: path,
    data,
  });

  return transformSectionResponse(response);
}

export async function destroy(sectionId: number): Promise<{ section: Section }> {
  const path = `${BASE_PATH}/${sectionId}`;

  const response = await api({
    method: 'delete',
    url: path,
  });

  return response.data;
}

export async function create({ baseType, webAppId }: SectionCreateProps): Promise<{ section: Section }> {
  const path = `${BASE_PATH}/${decamelize(baseType)}`;
  const data = serialize({
    section: { webAppId, view: 'home' },
  });
  const response = await api({
    method: 'post',
    url: path,
    data,
  });

  return transformSectionResponse(response);
}
