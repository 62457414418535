<script setup lang="ts">
import { camelize } from 'humps';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { WebAppService } from '@/types/web-app-service';

export interface Props {
  kind?: WebAppService['kind'];
  webAppServiceType?: WebAppService['type'];
}

const props = defineProps<Props>();

const kindTagColorClasses = {
  'redirect': 'bg-violet-500 text-white',
  'authenticated_redirect': 'bg-pink-400 text-white',
  'immediate': 'bg-rose-500 text-white',
  'scheduled': 'bg-blue-700 text-white',
};
const webAppServiceTypeTagColorClasses: Partial<Record<WebAppService['type'], string>> = {
  'WebAppServices::MediaGroup': 'bg-orange-500 text-white',
};
defineExpose({ kindTagColorClasses, webAppServiceTypeTagColorClasses });

const tagColorClass = computed(() =>
  (props.kind && kindTagColorClasses[props.kind]) ||
  (props.webAppServiceType && webAppServiceTypeTagColorClasses[props.webAppServiceType]),
);

const camelizedWebAppServiceType = computed(() => camelize(props.webAppServiceType?.split('::')?.pop() || ''));
const { t } = useI18n();
const label = computed(() => {
  if (props.kind) {
    return t(`webAppServices.service.kind.${props.kind}`);
  }
  if (props.webAppServiceType) {
    return t(`webAppServices.service.webAppServiceType.${camelizedWebAppServiceType.value}`);
  }

  return '';
});
</script>

<template>
  <span
    v-if="tagColorClass"
    class="rounded-full"
    :class="tagColorClass"
  >
    {{ label }}
  </span>
</template>
