<script setup lang="ts">
import { ref, computed, watch } from 'vue';

import type { CountryService } from '@/types/country-service';

import CountryServicesSelectList from './country-services-select-list.vue';

interface Props {
  modelValue: number[],
  countryServices: CountryService[];
  permittedCountryServices: CountryService[];
}

const props = defineProps<Props>();

const emit = defineEmits<{(e: 'update:modelValue', value: number[]): void}>();

const selected = ref(props.countryServices);
const selectedIds = computed(() => (selected.value.map((countryService) => (countryService.id))));

const unselected = computed(
  () => props.permittedCountryServices.filter(
    (service) => (!selectedIds.value.includes(service.id)),
  ),
);

const noSelected = computed(() => (selected.value.length === 0));
const noUnselected = computed(() => (unselected.value.length === 0));

function selectService(serviceId: number) {
  const selectedService = props.permittedCountryServices.find((service) => (service.id === serviceId));
  if (selectedService) {
    selected.value.push(selectedService);
  }
}

function removeService(serviceId: number) {
  const removedService = selected.value.find((service) => (service.id === serviceId));
  if (removedService) {
    selected.value.splice(selected.value.indexOf(removedService), 1);
  }
}

watch(selectedIds, () => {
  emit('update:modelValue', selectedIds.value);
});

</script>

<template>
  <div class="flex flex-col text-sm md:text-base">
    <div
      v-if="noSelected"
      class="text-gray-600"
    >
      {{ $t('countryServices.actions.notFound') }}
    </div>
    <country-services-select-list
      :country-services="selected"
      :actions="['delete']"
      @on-delete="(serviceId) => removeService(serviceId)"
    />
    <div class="my-4 font-semibold text-gray-800">
      {{ $t('countryServices.titles.more') }}
    </div>
    <country-services-select-list
      :country-services="unselected"
      :actions="['add']"
      @on-add="(serviceId) => selectService(serviceId)"
    />
    <div
      v-if="noUnselected"
      class="text-gray-600"
    >
      {{ $t('countryServices.actions.notFound') }}
    </div>
  </div>
</template>
