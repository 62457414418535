<script setup lang="ts">
import { provide } from 'vue';

import type { User } from '@/types/user';

interface Props {
  currentUser?: User;
}

const props = withDefaults(
  defineProps<Props>(), {
    currentUser: undefined,
  });

provide('currentUser', props.currentUser);

</script>

<template>
  <slot />
</template>
