<script setup lang="ts">
import { PhTrash } from '@phosphor-icons/vue';
import { useField } from 'vee-validate';
import { computed, ref } from 'vue';

export type Props = {
  modelValue?: File;
  label: string;
  description: string;
  name: string;
  required?: boolean;
  previewUrl?: string;
  disabled?: boolean;
  darkMode?: boolean;
  showDelete?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  previewUrl: undefined,
  disabled: false,
  darkMode: false,
});

const {
  handleBlur,
  handleChange,
} = useField(props.name, undefined, {
  initialValue: props.modelValue,
});

const fileInputRef = ref();
const newPreviewUrl = ref<string|undefined>();
const oldPreviewUrl = ref(props.previewUrl);

const previewImageUrl = computed(() => (newPreviewUrl.value || oldPreviewUrl.value));
const darkModeStyle = computed(() => (props.darkMode ? 'bg-gray-700' : ''));

const emit = defineEmits<{(e: 'update:modelValue', value: File): void ; (e: 'delete'): void }>();

function setFile(e: Event) {
  handleChange(e);
  const fileInput = e.target as HTMLInputElement;
  if (fileInput.files && fileInput.files[0]) {
    const file = fileInput.files[0];
    newPreviewUrl.value = URL.createObjectURL(file);
    emit('update:modelValue', file);
  } else {
    newPreviewUrl.value = undefined;
  }
}

function handleDelete() {
  newPreviewUrl.value = undefined;
  oldPreviewUrl.value = undefined;
  emit('delete');
}

function selectImage() {
  fileInputRef.value.click();
}

</script>
<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<template>
  <div class="flex gap-1.5">
    <div
      class="flex aspect-square h-16 w-16 items-center justify-center rounded-full border border-gray-300 p-2.5"
      :class="darkModeStyle"
    >
      <img
        v-if="previewImageUrl"
        :src="previewImageUrl"
        class="h-12 max-h-12 w-12 max-w-[3rem] object-contain"
      >
      <div
        v-else
        class="flex h-12 w-12	 flex-col justify-center"
      >
        <base-svg
          name="image"
          class="mx-auto h-6 w-6 fill-current text-gray-400"
        />
      </div>
    </div>
    <div
      class="flex flex-col justify-start 2xl:gap-y-1"
      :class="{ 'w-full gap-2 pl-2': showDelete }"
    >
      <label
        :for="name"
        class="text-xs font-semibold text-gray-900 "
      >
        {{ label }}
      </label>
      <p class="flex w-3/4 flex-col text-xs text-gray-500">
        {{ description }}
      </p>
      <div class="flex items-center justify-between">
        <button
          type="button"
          class="flex flex-col text-left text-2xs text-blue-500"
          @click="selectImage"
        >
          {{ $t('common.changeFile') }}
        </button>
        <button
          v-if="showDelete && (previewImageUrl || newPreviewUrl)"
          type="button"
          @click="handleDelete"
        >
          <PhTrash
            :size="16"
            class="flex cursor-pointer flex-col text-gray-400"
          />
        </button>
      </div>
      <input
        :id="name"
        ref="fileInputRef"
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        :name="name"
        class="hidden"
        @change="setFile"
        @blur="handleBlur"
      >
    </div>
  </div>
</template>
