import { useI18n } from 'vue-i18n';

interface ButtonStyle {
  color?: string;
}
interface Button {
  name: string;
  iconFileName: string;
  style?: ButtonStyle,
  onClick: () => void;
  isActive?: () => boolean,
  tooltip: string,
}

function getTooltip(button: string, info?: string) {
  const { t } = useI18n();
  const infoText = info ? ` (${info})` : '';

  return `${t(`editor.${button}.tooltip`)}${infoText}`;
}

function getRemoveColorButton(editor: any) {
  return {
    name: 'removeColor',
    iconFileName: 'circle',
    onClick: () => editor.chain().focus().unsetColor()
      .run(),
    tooltip: getTooltip('removeColor'),
  };
}

function getColorButtons(
  editor: any,
  colors: string[],
) {
  const colorButtons: Button[] = colors.map((color) => ({
    name: 'color',
    iconFileName: 'circle',
    style: { color },
    onClick: () => editor.chain().focus().setColor(color)
      .run(),
    isActive: () => editor && editor.isActive('textStyle', { color }),
    tooltip: getTooltip('color', color),
  }));
  if (colors.length > 0) colorButtons.unshift(getRemoveColorButton(editor));

  return colorButtons;
}

function getToolbarButtons(
  editor: any,
  titleLevel: number,
  colors: string[],
  options: string[],
): Button[] {
  const colorButtons = getColorButtons(editor, colors);

  const allOptions = [
    {
      name: 'bold',
      iconFileName: 'format-bold',
      onClick: () => editor.chain().focus().toggleBold()
        .run(),
      isActive: () => editor && editor.isActive('bold'),
      tooltip: getTooltip('bold', 'Ctrl / ⌘ + B'),
    },
    {
      name: 'italic',
      iconFileName: 'format-italic',
      onClick: () => editor.chain().focus().toggleItalic()
        .run(),
      isActive: () => editor && editor.isActive('italic'),
      tooltip: getTooltip('italic', 'Ctrl / ⌘ + I'),
    },
    {
      name: 'strike',
      iconFileName: 'format-strike',
      onClick: () => editor.chain().focus().toggleStrike()
        .run(),
      isActive: () => editor && editor.isActive('strike'),
      tooltip: getTooltip('strike', 'Ctrl / ⌘ + ⇧ + X'),
    },
    {
      name: 'title',
      iconFileName: 'format-title',
      onClick: () => editor.chain().focus().toggleHeading({ level: titleLevel })
        .run(),
      isActive: () => editor && editor.isActive('heading', { level: titleLevel }),
      tooltip: getTooltip('title'),
    },
    ...colorButtons,
    {
      name: 'quote',
      iconFileName: 'format-quote',
      onClick: () => editor.chain().focus().toggleBlockquote()
        .run(),
      isActive: () => editor && editor.isActive('blockquote'),
      tooltip: `${getTooltip('quote')} (>)`,
    },
    {
      name: 'code',
      iconFileName: 'format-code',
      onClick: () => editor.chain().focus().toggleCodeBlock()
        .run(),
      isActive: () => editor && editor.isActive('codeBlock'),
      tooltip: `${getTooltip('code')} (\`\`)`,
    },
    {
      name: 'bulletList',
      iconFileName: 'format-bullet-list',
      onClick: () => editor.chain().focus().toggleBulletList()
        .run(),
      isActive: () => editor && editor.isActive('bulletList'),
      tooltip: `${getTooltip('bulletList')} (-)`,
    },
    {
      name: 'orderedList',
      iconFileName: 'format-numbered-list',
      onClick: () => editor.chain().focus().toggleOrderedList()
        .run(),
      isActive: () => editor && editor.isActive('orderedList'),
      tooltip: `${getTooltip('orderedList')} (1.)`,
    },
    {
      name: 'undo',
      iconFileName: 'undo-icon',
      onClick: () => editor.chain().focus().undo()
        .run(),
      tooltip: `${getTooltip('undo')} (Ctrl / ⌘ + Z)`,
    },
    {
      name: 'redo',
      iconFileName: 'redo-icon',
      onClick: () => editor.chain().focus().redo()
        .run(),
      tooltip: `${getTooltip('redo')} (Ctrl / ⌘ + Y)`,
    },
  ];

  if (options.length > 0) {
    return allOptions.filter((option) => options.includes(option.name));
  }

  return allOptions;
}

export default getToolbarButtons;
