<script setup lang="ts">
import { computed, useAttrs, type Component } from 'vue';

export type Props = {
  icon: Component
  theme?: 'primary' | 'secondary';
  href?: string;
  stop?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  theme: 'primary',
  href: undefined,
  stop: false,
});

const emits = defineEmits<{(e: 'stop'): void, (e: 'click'): void}>();

const attrs = useAttrs();
const canRedirect = computed(() => (props.href && !props.stop));
const currentTag = computed(() => (canRedirect.value ? 'a' : 'button'));
const buttonStyles = {
  primary: 'bg-primary-700 hover:bg-primary-600 disabled:bg-primary-300',
  secondary: 'bg-purple-400 hover:bg-purple-300 disabled:bg-purple-300',
};

const buttonStyle = computed(() => buttonStyles[props.theme]);
function handleClick() {
  if (props.stop) {
    emits('stop');
  } else {
    emits('click');
  }
}

</script>

<template>
  <component
    :is="currentTag"
    class="absolute z-10 flex h-min justify-center self-center rounded-r-md p-2"
    v-bind="attrs"
    :href="href"
    :class="buttonStyle"
    @click="handleClick"
  >
    <component
      :is="icon"
      class="text-white"
      size="16px"
    />
  </component>
</template>
